import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'

export interface RenderCondition {
  target: RENDER_CONDITION_TARGETS
  path?: string // e.g. target[path], else just check target
  fn: (val: any) => boolean // comparison function
}

// Currrently the only recognized targets for conditional expression checks. We have to adhere to whatever
// targets and rules are defined so that there isn't disconnect between metadata and application layer logic
export enum RENDER_CONDITION_TARGETS {
  SELECTED_PREFERENCES = 'selectedPreferences',
}

export enum INFO_OVERLAY_TARGET_FNS {
  PRESS_GENDER_PREF_DISCLAIMER = 'PRESS_GENDER_PREF_DISCLAIMER',
  PRESS_CARE_TEAM_LINK = 'PRESS_CARE_TEAM_LINK',
}

export interface PreferenceMetadataConfig {
  name: string
  title: {
    defaultMessage: string
    description: string
  }
  options: {
    text: {
      defaultMessage: string
      description: string
    }
    id: MEMBER_PREFERENCE_IDS
    description?: {
      value: {
        defaultMessage: string
        description: string
      }
    }[]
  }[]
  // In addition to visibleFor rules, must satisfy expressions listed here to show the accordion
  renderConditions?: RenderCondition[]
  icon: string
  promptText?: {
    defaultMessage: string
    description: string
  }
  footerText?: PreferenceMetadataConfigFooterText
  visibleFor: S4C_PROGRAM_OPTION_FLOW[]
}

// Separating complexity out so it can be used as a type in MP logic
export interface PreferenceMetadataConfigFooterText {
  message: {
    defaultMessage: string
    description: string
  }
  infoOverlay?: {
    onPress?: {
      fn: INFO_OVERLAY_TARGET_FNS
    }
    content: {
      defaultMessage: string
      description: string
    }
    linkConfig?: {
      // Optional field -- Support links within the info overlay body
      onPress: {
        fn: INFO_OVERLAY_TARGET_FNS // Function to call when the link is pressed
        params: any // Pass into the function call
      }
    }
  }
  // Some footerTexts only render after the value has been selected
  renderConditions?: RenderCondition[]
  color: string
}
