import { ProgramNames, TriageSearch } from '../../models'
import { DirectPathBookingApplinkParams } from '../searchForCare/constants'

export enum ESSENTIALS_CATEGORY {
  PARENTING_SUPPORT = 'parenting-support',
  STRENGTHENING_RELATIONSHIPS = 'strengthening-relationships',
}

export enum ESSENTIALS_SUBCATEGORY {
  NEW_FRIENDS_AND_RELATIONSHIPS = 'new-friends-and-relationships',
}

export enum ESSENTIALS_COURSE {
  PARENTING_IN_THE_REAL_WORLD = 'parenting-in-the-real-world',
}

/** Callback function for pressing on essentials content */
export type OnEssentialsCardPressFunction = (
  title: string,
  subPath: string,
  contentFormat: string,
  contentType: string,
  destinationSlug: string,
  locale: string,
) => void

/** Article data returned from goodness apis */
export interface EssentialsArticleRaw {
  title: string
  uri: string
  image: { url: string }[]
  contentType: string
  readTime: string
  id: number
  contentFormat: string
}

/** Course data returned from goodness apis */
export interface EssentialsCourseRaw {
  title: string
  uri: string
  foreground: { url: string }[]
  id: number
  subcategories: { id: number; title: string }[]
}

/** Essentials content data from goodness apis after being processed and formatted */
export interface WellnessToolkitEssentialsContent {
  title: string
  imageUrl: string
  contentType: string
  readTime: string
  id?: number
  contentFormat: string
  categoryType?: SELF_CARE_CATEGORY_TYPE
  onPress: () => void
}

export type ToolkitEssentialsContentRaw = EssentialsArticleRaw | EssentialsCourseRaw

export enum WELLNESS_TOOLKIT_PROMOTION {
  WORK = 'work',
  PURPOSE = 'purpose',
  RELATIONSHIPS = 'relationships',
  PARENT = 'parent',
  FAMILY = 'family',
}

export enum SELF_CARE_CATEGORY_TYPE {
  TOPIC = 'topic',
  TOOL = 'tool',
  COURSE = 'course',
}

export enum WELLNESS_TOOLKIT_TYPE {
  PARENT_COACHING = 'parentCoaching',
  INDIVIDUAL_COACHING = 'individualCoaching',
  FAMILY_HUB = 'familyHub',
}

/**
 * Each prop (excluding `configExtras`) is a condition that may or may not apply to a given toolkit:
 * - `true` = the condition applies and must be met for a given toolkit to be shown
 * - `false` = the condition does not apply to the given toolkit
 *
 * `configExtras` contains any additional configurations that is not a condition
 */
export interface WellnessToolkitAvailableConfig {
  shouldNotBeTeen: boolean
  shouldNotBeInternational: boolean
  shouldEssentialsBeEnabled: boolean
  shouldCoachingBeEnabled: boolean
  /** Checks shouldHideChildSearchCTA, hideDependentField */
  shouldDependentsBeSupported: boolean
  shouldNotBeSHOrSI: boolean
  /** Speculative search. Must pass `programSearchRequestData` in `configExtras` in order to run the search */
  shouldHaveProviderSupply: boolean
  shouldNotBeInActiveEpisode: boolean
  shouldNotHaveT3Recommendation: boolean
  shouldBeKnownParent: boolean
  shouldFeatureFlagBeEnabled: boolean
  configExtras?: {
    allowedProgramsForActiveEpisodes?: ProgramNames[]
    programSearchRequestData?: DirectPathBookingApplinkParams
    triageConditionsChecker?: (triageSearches: TriageSearch[]) => boolean
  }
}
