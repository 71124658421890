import { matchPath } from 'react-router'

import axios from 'axios'
import { pickBy } from 'lodash-es'
import { Store } from 'redux'

import { API_FEATURES_HEADER_NAME, getDevFlags, setContextUUIDHeader } from '@lyrahealth-inc/shared-app-logic'

import mixpanel from '../../data/mixpanel/mixpanel-browser'
import { getId } from '../../data/user/userSelectors'
import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'
import { CSRF_HEADER_NAME, CSRF_TOKEN, UNAUTHENTICATED_REDIRECT_URL } from '../constants/appConstants'
import { logToSumoLogic, SUMO_CATEGORY } from '../utils/userUtils'

const NO_X_FEATURES_URLS = [/(?:https?:\/\/)vendorapi\.([a-zA-Z0-9-]+\.)?lyrahealth\.com\/.*/]

// baseURL Works in almost all cases, may need to be overridden a few times
const instance = axios.create({
  baseURL: '/services',
})

const interceptor = (store: Store): void => {
  instance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        if (!matchPath('register/mfa/:id/verify', window.location.pathname)) {
          window.localStorage.setItem(
            UNAUTHENTICATED_REDIRECT_URL,
            `${window.location.pathname + window.location.search + window.location.hash}`,
          )
        }
        mixpanel.reset()
        pendo?.clearSession?.()
        let targetUrl = ''
        if (window.location.pathname.includes(PAGE_ROUTES.ESSENTIALS)) {
          targetUrl = PAGE_ROUTES.PATHWAYS.ESSENTIALS_HOMEPAGE_WIDGET
        } else {
          targetUrl = '/login'
        }
        // persist query params (including utm params)
        window.location.href = `${targetUrl}${window.location.search}`
      }
      const sourceCategory =
        process.env.NODE_ENV === 'production' ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG
      const state = store.getState()
      const lyraId = getId(state)
      logToSumoLogic(sourceCategory, lyraId, {
        messsage: `Request Error - ${error}`,
        type: 'error',
        error,
        sourceCategory: process.env.NODE_ENV === 'production' ? SUMO_CATEGORY.PROD_DEBUG : SUMO_CATEGORY.STAGING_DEBUG,
      })
      return Promise.reject(error)
    },
  )

  instance.interceptors.request.use(function (config) {
    const csrfHeaderName = localStorage.getItem(CSRF_HEADER_NAME)
    const csrfToken = localStorage.getItem(CSRF_TOKEN)
    if (csrfHeaderName && csrfToken && config.headers) {
      config.headers[csrfHeaderName] = csrfToken
    }

    // Pass dev flags from test hooks to LW API via response header
    const { url } = config
    const skipFeaturesHeader = !url || NO_X_FEATURES_URLS.some((regex) => regex.test(url))
    const enabledFeatureFlags = Object.keys(pickBy(getDevFlags(), (value) => value === true))
    if (!skipFeaturesHeader && enabledFeatureFlags.length > 0 && config.headers) {
      config.headers[API_FEATURES_HEADER_NAME] = enabledFeatureFlags.join(',')
    }

    setContextUUIDHeader(config)
    return config
  })
}

export default {
  interceptor,
}

export const axiosInstance = instance
