import { differenceInYears, formatISO, parse } from 'date-fns'
import { List, Map } from 'immutable'
import { isArray, isEmpty } from 'lodash-es'
import { createSelector } from 'reselect'

import {
  COUNTRY_CATEGORY,
  DEFAULT_LANGUAGE,
  getAge,
  ImmutableTypedMap,
  isAgeTeen,
  MEETING_FORMATS,
  NotificationItemState,
  PREFERRED_APPOINTMENT_MODALITIES,
  User,
} from '@lyrahealth-inc/shared-app-logic'

import { INTERNATIONAL_CONSENT_USER_STATUS } from '../../common/constants/appConstants'
import {
  getOnboardLocation,
  getOnboardTimezoneForAvailabilityRequest,
} from '../../features/onboard/data/onboardSelectors'
import { getIsDetectedCountryInternational } from '../appGlobals/appGlobalsSelectors'
import {
  getCustomerSupportedPrograms,
  getIsCustomerInternational,
  getIsCustomerLyra,
  getIsLiveChatEnabled,
} from '../customer/customerSelectors'

export const getUser = (state: Map<string, any>) => state?.get('user')

export const getId = createSelector(getUser, (user) => user?.get('lyraId'))

export const getHealthPlan = createSelector(getUser, (user) => user?.get('healthPlan'))

export const getUsername = createSelector(getUser, (user) => user?.get('username'))

export const getUserLegalFirstName = createSelector(getUser, (user) => user?.get('firstname'))

export const getUserLegalLastName = createSelector(getUser, (user) => user?.get('lastname'))

export const getUserDisplayFirstName = createSelector(
  getUser,
  (user) => user?.get('preferredFirstName') || user?.get('firstname'),
)

export const getUserDisplayLastName = createSelector(
  getUser,
  (user) => user?.get('preferredLastName') || user?.get('lastname'),
)

export const getUserPreferredFirstName = createSelector(getUser, (user) => user?.get('preferredFirstName'))

export const getUserPreferredLastName = createSelector(getUser, (user) => user?.get('preferredLastName'))

/**
 * WARNING: Backend returns DOB in `MM/dd/yyyy` format, which does not conform to JavaScript's date time string format,
 * a variant of ISO 8601. `Date()` is not gauranteed to work with other formats, and behavior may vary across browsers.
 *
 * See reference: [Date#date_time_string_format](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format)
 *
 * If you need a `Date` object, use `getUserDOBDate` selector
 */
export const getUserDOB = createSelector(getUser, (user) => user?.get('dob'))

export const getUserDOBDate = createSelector(getUserDOB, (userDOB: string | undefined) =>
  userDOB ? parse(userDOB, 'MM/dd/yyyy', new Date()) : undefined,
)

/**
 * Returns user DOB as a ISO 8601 date string, ex: 2023-01-02
 */
export const getUserDOBISO = createSelector(getUserDOBDate, (userDOBDate: Date | undefined) =>
  userDOBDate ? formatISO(userDOBDate, { representation: 'date' }) : undefined,
)

export const getUserGender = createSelector(getUser, (user) => user?.get('gender'))

export const getUserAge = createSelector(getUserDOBDate, (userDOBDate: Date | undefined) => {
  return userDOBDate ? differenceInYears(new Date(), userDOBDate) : undefined
})

export const getIsUserMinor = createSelector(getUserAge, (userAge) => {
  return userAge ? userAge <= 17 : undefined
})

export const getIsUserTeen = createSelector(getUserAge, (age) => {
  return age ? isAgeTeen(age) : false
})

export const getIsUserMfaEnabled = createSelector(getUser, (user) => user?.get('mfa'))

export const getUserFriendlyLyraCode = createSelector(
  getUser,
  (user) => user?.get('friendlyLyraCode') as string | undefined,
)

// This selector is only used for CNT booking since the timeZoneId may be different than the logged in user (aka Care Navigator booking for a client)
export const getClientLocationTimeZone = createSelector(
  (state: $TSFixMe) => getOnboardLocation(state),
  (onboard) => onboard?.get('timeZoneId'),
)

// Get the user's time zone if it is set explicitly. Otherwise,
// default to the time zone of the search location they entered
// when searching for provider availability. This is used to display
// dates and times localized according to the user's time zone.
// When localizing dates and times, we want to follow this hierarchy
// for obtaining the right time zone:
// 1) user's explicitly set time zone (`user.timeZone`)
// 2) time zone corresponding to the user's search location
// 3) the browser guess of the user's time zone (this comes from the default of the `getOnboardTimezoneForAvailabilityRequest` onboard selector)
export const getTimeZoneOrDefault = createSelector(
  getUser,
  (state: $TSFixMe) => getOnboardTimezoneForAvailabilityRequest(state),
  (user: $TSFixMe, timeZoneForAvailabilityRequest: $TSFixMe) => user?.get('timeZone') ?? timeZoneForAvailabilityRequest,
)

// Get the user's saved time zone WITHOUT a default. This is needed for the user's profile so that
// we can show when the user does not have a saved time zone. We don't want to display any kind of
// default on the profile so the user will be aware that the time zone is missing from their
// profile information.
export const getTimeZone = createSelector(getUser, (user) => user?.get('timeZone'))

export const getTimeZoneOrDefaultIgnoreOnboarding = createSelector(
  getUser,
  (user) => user?.get('timeZone') ?? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
)

export const getIsUserInternational = createSelector(getUser, (user) => user?.get('isInternational') ?? false)

export const getIsUserInternationalAndMinor = createSelector(
  getIsUserMinor,
  getIsUserInternational,
  (isUserMinor, isUserInternational) => isUserMinor && isUserInternational,
)

// gets the users legal first and legal last name locally formatted
export const getUserLegalName = createSelector(
  getUserLegalFirstName,
  getUserLegalLastName,
  getIsUserInternational,
  (firstName: string, lastName: string, isInternational: boolean) => {
    return isInternational ? `${lastName}, ${firstName}` : `${firstName} ${lastName}`
  },
)

// gets the users display first and display last name locally formatted
export const getUserDisplayName = createSelector(
  [
    getUserDisplayFirstName,
    getUserDisplayLastName,
    getUserLegalFirstName,
    getUserLegalLastName,
    getIsUserInternational,
    (_, isPreferredNameEnabled) => isPreferredNameEnabled,
  ],
  (displayFirstName, displayLastName, legalFirstName, legalLastName, isUserInternational, isPreferredNameEnabled) => {
    const firstName = isPreferredNameEnabled ? displayFirstName || legalFirstName : legalFirstName
    const lastName = isPreferredNameEnabled ? displayLastName || legalLastName : legalLastName
    return isUserInternational ? `${lastName}, ${firstName}` : `${firstName} ${lastName}`
  },
)
export const getUserCountryCategory = createSelector(getIsUserInternational, (isUserInternational) => {
  return isUserInternational ? COUNTRY_CATEGORY.INTERNATIONAL : COUNTRY_CATEGORY.DOMESTIC
})

export const getIsClientDataAvailable = createSelector(getUser, (user) => user?.get('isClientDataAvailable') ?? false)

export const getUserInternationalConsent = createSelector(
  getUser,
  (user) => user?.get('internationalConsent') ?? INTERNATIONAL_CONSENT_USER_STATUS.NONE,
)

export const getUserCreateDate = createSelector(getUser, (user) => user?.get('createDate', ''))

export const getIsUserLoggedIn = createSelector(getUser, (user) => user.size > 0)

export const getUserPreferredAppointmentTypes = createSelector(getUser, (user) =>
  user?.get('preferredAppointmentTypes', List()),
)

export const getUserPrefersInPersonAppointmentType = createSelector(
  getUserPreferredAppointmentTypes,
  (preferredAppointmentTypes) => {
    return preferredAppointmentTypes.toJS().includes(MEETING_FORMATS.IN_PERSON)
  },
)

// Do not use to populate member preferences in search payload -- use member preferences shared-app-logic utils
export const getUserModalityPreference = createSelector(getUserPreferredAppointmentTypes, (modalities) => {
  return modalities.size > 1 ? PREFERRED_APPOINTMENT_MODALITIES.NO_PREFERENCE : modalities.join()
})

export const getHasUserProvidedInternationalConsentResponse = createSelector(
  getUserInternationalConsent,
  (userInternationalConsent) => {
    return userInternationalConsent !== INTERNATIONAL_CONSENT_USER_STATUS.NONE
  },
)

export const getIsUserInternationalAndOfInternationalCustomer = createSelector(
  getIsUserLoggedIn,
  (state: $TSFixMe) => getIsCustomerInternational(state),
  getIsUserInternational,
  (isLoggedIn: $TSFixMe, isCustomerInternational: $TSFixMe, isUserInternational: $TSFixMe) => {
    return isLoggedIn && isCustomerInternational && isUserInternational
  },
)

export const getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer = createSelector(
  getIsUserLoggedIn,
  (state: $TSFixMe) => getIsCustomerInternational(state),
  (state: $TSFixMe) => getIsDetectedCountryInternational(state),
  (isUserLoggedIn: $TSFixMe, isCustomerInternational: $TSFixMe, isDetectedCountryInternational: $TSFixMe) => {
    return isCustomerInternational && !isUserLoggedIn && isDetectedCountryInternational
  },
)

export const getShouldHideChatForUser = createSelector(
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternationalAndOfInternationalCustomer,
  (state: $TSFixMe) => getIsCustomerLyra(state),
  (
    isLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
    isUserInternationalAndOfInternationalCustomer,
    isCustomerLyra,
  ): boolean => {
    return (
      isCustomerLyra ||
      isLoggedOutUserAssumedInternationalAndOfInternationalCustomer ||
      isUserInternationalAndOfInternationalCustomer
    )
  },
)

export const getUserCustomerCountries = createSelector(getUser, (user) => user?.get('customerCountries') ?? Map())

export const getUserCountryName = createSelector(
  getUserCustomerCountries,
  (customerCountries) => customerCountries?.get('countryName') ?? '',
)

export const getUserCountryCode = createSelector(
  getUserCustomerCountries,
  (customerCountries) => customerCountries?.get('countryIsoCode') ?? '',
)

export const getUserCustomerCountryId = createSelector(getUserCustomerCountries, (customerCountries) =>
  customerCountries?.get('id'),
)

export const getUserCountryId = createSelector(getUserCustomerCountries, (customerCountries) =>
  customerCountries?.get('countryId'),
)

export const getUserCountryInternationalConsentContents = createSelector(
  getUserCustomerCountries,
  (customerCountries) => customerCountries?.get('internationalConsent') ?? Map(),
)

export const getAccessToInternationallySupportedTreatmentOptions = createSelector(
  getIsUserInternational,
  (state: $TSFixMe) => getCustomerSupportedPrograms(state),
  (isUserInternational: $TSFixMe, customerSupportedPrograms: $TSFixMe) => {
    return isUserInternational && !isEmpty(customerSupportedPrograms.toJS())
  },
)

export const getShouldUserSeeInternationalConsentForDataCollection = createSelector(
  getAccessToInternationallySupportedTreatmentOptions,
  getHasUserProvidedInternationalConsentResponse,
  (hasAccessToInternationallySupportedTreatmentOptions, hasUserProvidedInternationalConsentResponse) => {
    return hasAccessToInternationallySupportedTreatmentOptions && !hasUserProvidedInternationalConsentResponse
  },
)

export const getUserPhoneCountryCode = createSelector(getUser, (user) => user?.get('phoneCountryCode'))

export const getUserPhoneNumber = createSelector(getUser, (user) => user?.get('phone'))

export const getUserDisplayLanguage = createSelector(getUser, (user) => user?.get('displayLanguage'))

export const getUserDisplayLanguageOrDefault = createSelector(getUser, getIsUserLoggedIn, (user, isLoggedIn) =>
  isLoggedIn ? user?.get('displayLanguage') ?? DEFAULT_LANGUAGE : undefined,
)

// If you use this selector, be sure to call the endpoint getDirectDedicatedCareNavigatorLink to keep this selector updated.
export const getUserDirectDedicatedCareNavigatorLink = createSelector(getUser, (user) =>
  user?.get('directDedicatedCareNavigatorLink'),
)

export const getUserSalesforceId = createSelector(getUser, (user) => user?.get('salesforceId'))

export const isUserCountryInternational = createSelector(getUserCountryCode, (countryCode) => {
  return countryCode && countryCode !== 'US'
})

export const getUserChildren = createSelector(getUser, (user) =>
  user?.get('children')?.filter((child: ImmutableTypedMap<User>) => !child.get('disabled')),
)

// get user's children that are 18 or older
export const getAdultChildren = createSelector(getUserChildren, (children) => {
  return children
    ?.toJS()
    ?.filter((childUser: User) => getAge({ date: childUser?.dob ?? '', dateFormat: 'MM/DD/YYYY' }) >= 18)
})

// get user's children that have registered their own LW account (e.g. LCT teen)
export const getUserRegisteredChildren = createSelector(getUserChildren, (children) => {
  return children?.toJS()?.filter((childUser: User) => childUser.emailVerified === true)
})

// get user's children that are under 18 and not registered their own accounts
export const getUserUnregisteredMinorChildren = createSelector(
  [getUserChildren, getAdultChildren, getUserRegisteredChildren],
  (
    children: ImmutableTypedMap<User[] | undefined>,
    adultChildren: User[] | undefined,
    registeredChildren: User[] | undefined,
  ) =>
    children
      ?.toJS()
      ?.filter(
        (childUser: User) =>
          !adultChildren?.some((c) => c.lyraId === childUser.lyraId) &&
          !registeredChildren?.some((c) => c.lyraId === childUser.lyraId),
      ),
)

// Even if a user has no children the API will respond with [], which will be placed into redux state
export const getHasFetchedChildUsers = createSelector(getUserChildren, (children) => {
  return isArray(children?.toJS())
})

export const getUserRemainingTime = createSelector(getUser, (user) => user?.get('sessionRemainingTime'))

export const getIsChatEnabledForUser = createSelector(
  (state: Map<string, any>) => getIsLiveChatEnabled(state),
  (state: Map<string, any>) => getIsCustomerInternational(state),
  getIsUserInternational,
  getShouldHideChatForUser,
  (isLiveChatEnabled, isCustomerInternational, isUserInternational, shouldHideChatForUser) => {
    return isCustomerInternational || isUserInternational ? !shouldHideChatForUser : isLiveChatEnabled
  },
)

export const getUserHasIndicatedIsGuardian = createSelector(getUser, (user) => user?.get('hasIndicatedIsGuardian'))

export const getUserDedicatedCareNavigatorInfo = createSelector(getUser, (user) =>
  user?.get('dedicatedCareNavigatorInfo'),
)

export const getNotificationStates = createSelector(getUser, (user) => {
  return user?.get('notifications')
})

export const getUpdateEmailAddressNotificationState = createSelector(
  getNotificationStates,
  (notificationStates): NotificationItemState => {
    return notificationStates?.get('updateEmailAddress')
  },
)

export const getShouldShowAccountBadge = createSelector(getNotificationStates, (notificationStates): boolean => {
  return notificationStates?.get('updateEmailAddress') === 'new'
})
