import React from 'react'
import { connect } from 'react-redux'

import { noop } from 'lodash-es'

import { MIXPANEL_PROPERTY, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { AppContext } from '@lyrahealth-inc/ui-core-crossplatform'

import { initiateSSOForLyraLearn } from '../../common/http/data/customer'
import { getDetectedCountryISO } from '../../data/appGlobals/appGlobalsSelectors'
import { getCustomerName } from '../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { TrackEventWithObjAction } from '../../data/mixpanel/types'

/*
ApplicationContext is used to add context to ui-core-crossplatform. In particular CustomerName and domain are currently
used for opening links in ui-core-crossplatform
*/
function ApplicationContext({
  customerName,
  detectedCountryIso,
  children,
  trackEventWithObj,
}: {
  customerName: string
  detectedCountryIso: string
  children: React.ReactNode
  trackEventWithObj: TrackEventWithObjAction
}) {
  const domain = process.env.NODE_ENV !== 'production' ? 'staging.lyrahealth.com' : 'lyrahealth.com'
  const copyPasteDisabled = false
  const trackEvent = (event: string, eventProps?: { [key in MIXPANEL_PROPERTY]?: any }) => {
    trackEventWithObj({
      event,
      ...eventProps,
    })
  }

  const {
    isLatestMatchesEnabled,
    maxBenefitsToShowInExplorer,
    isClientDataShareEnabled,
    shouldDisplayFastTrackBooking,
    isLctTeensEnabled,
    isCHCOutage,
    isHPIConsentWithMedicareEnabled,
    isParentLedBookingEnabled,
    shouldShowLCPositioning,
    showEnhancedProviderResults,
    hideUserTestimonialsFromLCxModal,
    isDedicatedCareNavigatorEnabled,
    shouldSeePendoGuides,
    shouldShowCostEstimatesAsFullyCovered,
    isCostMessagingEnabled,
    isFamilyHubEnabled,
    isPreferredNameEnabled,
    shouldShowNewMinimizedIntegratedBenefitsDesign,
    showPickedForYouV2,
    showUpdatedConnectedCalendar,
    showPickedForYouV3,
  } = useFlags()
  return (
    <AppContext.Provider
      value={{
        initiateSSOForLyraLearn,
        customerName,
        detectedCountryIso,
        domain,
        copyPasteDisabled,
        setModalStatusOpen: noop,
        isLatestMatchesEnabled,
        trackEvent,
        maxBenefitsToShowInExplorer,
        isClientDataShareEnabled,
        shouldDisplayFastTrackBooking,
        isLctTeensEnabled,
        isCHCOutage,
        isHPIConsentWithMedicareEnabled,
        isParentLedBookingEnabled,
        shouldShowLCPositioning,
        showEnhancedProviderResults,
        hideUserTestimonialsFromLCxModal,
        isDedicatedCareNavigatorEnabled,
        shouldSeePendoGuides,
        shouldShowCostEstimatesAsFullyCovered,
        isCostMessagingEnabled,
        isFamilyHubEnabled,
        isPreferredNameEnabled,
        shouldShowNewMinimizedIntegratedBenefitsDesign,
        showPickedForYouV2,
        showUpdatedConnectedCalendar,
        showPickedForYouV3,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default connect(
  (state) => ({ customerName: getCustomerName(state), detectedCountryIso: getDetectedCountryISO(state) }),
  { trackEventWithObj },
)(ApplicationContext)
