import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import {
  SEARCHER_TYPE_TO_MATCH_FOR,
  SearcherInfo,
  useFlags,
  useInProgressSearch,
  useSearchAgeAndType,
} from '@lyrahealth-inc/shared-app-logic'

import { useGetUserDisplayName } from './useGetUserDisplayName'
import { getSessions } from '../../data/lyraTherapy/lyraTherapySelectors'
import { getUserAge, getUserDOB } from '../../data/user/userSelectors'
import { handleZeroViewSelectArgs } from '../../features/appointmentDashboard/data/appointmentDashboardActions'
import { getSearches } from '../../features/triage/data/triageSelectors'

export const useNavigateToSearchForCare = ({
  handleZeroViewSelect,
  setSearcherInfo,
}: {
  handleZeroViewSelect: (args: handleZeroViewSelectArgs) => void
  setSearcherInfo: (searcherInfo: SearcherInfo) => {
    type: string
    data: SearcherInfo
  }
}) => {
  const navigate = useNavigate()
  const { firstName, lastName } = useGetUserDisplayName()
  const userAge = useSelector(getUserAge)
  const userDOB = useSelector(getUserDOB)
  const searches = useSelector(getSearches)?.toJS()
  const providerSessions = useSelector(getSessions)
  const inProgressSearch = useInProgressSearch(searches, providerSessions.appointments)
  const getSearchType = useSearchAgeAndType()
  const { shouldShowTriageSummary } = useFlags()

  return useCallback(() => {
    if (userAge === undefined) {
      console.warn('Failed to navigate to search for care - missing user data')
      return
    }

    handleZeroViewSelect({
      whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF,
      navigate,
      currentLocation: location.pathname,
      showSummaryView: shouldShowTriageSummary && inProgressSearch === undefined,
    })
    const { type } = getSearchType({ dob: userDOB, matchFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF })
    setSearcherInfo({
      name: firstName,
      age: userAge,
      type,
      dob: userDOB,
      lastName,
    })
  }, [
    firstName,
    getSearchType,
    handleZeroViewSelect,
    inProgressSearch,
    lastName,
    navigate,
    setSearcherInfo,
    shouldShowTriageSummary,
    userAge,
    userDOB,
  ])
}
