import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  EMAIL_CAPTURE_PAGE_TITLE,
  EmailCaptureVariant,
  genericErrorMessage,
  TRIAGE_ENTRY_POINTS,
  validateEmailForm,
} from '@lyrahealth-inc/shared-app-logic'

import {
  FormContainer,
  RegistrationGeneralPathwayIllustration,
  RegistrationProviderPathwayIllustration,
  RegistrationSelfCarePathwayIllustration,
  SsoButton,
} from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { InputFieldRFF } from '../../atoms/formElements/InputField'
import { Link } from '../../atoms/link/Link'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { SSO_BUTTON_MESSAGE_TYPE, SSO_BUTTON_TYPE } from '../../atoms/ssoButton/SsoButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { InlineErrorBanner, InputAccessory } from '../../molecules'
import { NavBar } from '../../molecules/navBar/NavBar'
import { SubheadSize } from '../../styles/typeStyles'
import { Flex1View, StickyView } from '../../templates'
import { ThemeType, tID } from '../../utils'

export interface EmailCaptureFormValues {
  emailAddress: string
}

const Flex1Container = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundTransparent,
  paddingBottom: theme.spacing['16px'],
  ...(theme.breakpoints.isMinWidthTablet && {
    borderRadius: 16,
    padding: '32px 48px',
    margin: '0 auto 48px auto',
    width: 446,
    display: 'flex',
    flexDirection: 'column',
  }),
}))

const EmailFormContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMinWidthTablet
    ? 0
    : `${theme.spacing['0px']} ${theme.spacing['16px']} ${theme.spacing['16px']}`,
}))

const HeaderContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  marginBottom: theme.spacing['24px'],
}))

const DividerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginVertical: theme.spacing['24px'],
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const DividerText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.spacing['24px'],
  color: theme.colors.textSecondary,
}))

const SplitDivider = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 1,
  height: 1,
  backgroundColor: theme.colors.dividerSecondary,
}))

const IllustrationContainer = styled.View({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const FooterTextContainer = styled.View({
  width: '100%',
  alignItems: 'center',
})

const ButtonGroupContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing['8px'],
}))

const ButtonContainer = styled.View<{ theme: ThemeType; addMarginBottom?: boolean }>(({ theme, addMarginBottom }) => ({
  width: '100%',
  ...(addMarginBottom && {
    marginBottom: theme.spacing['16px'],
  }),
}))

const AlreadyHaveAccountText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textSecondary,
}))

const RecaptchaContainer = styled.View<{ theme: ThemeType; lessMargin: boolean }>(({ theme, lessMargin }) => ({
  marginTop: lessMargin ? theme.spacing['24px'] : theme.spacing['72px'],
}))

const getVariantDataByPathway = (formatMessage: IntlShape['formatMessage'], pathway?: TRIAGE_ENTRY_POINTS) => {
  switch (pathway) {
    case TRIAGE_ENTRY_POINTS.FORKED_TRIAGE:
      return {
        title: formatMessage(EMAIL_CAPTURE_PAGE_TITLE[EmailCaptureVariant.PROVIDER]),
        illustration: <RegistrationProviderPathwayIllustration />,
      }
    case TRIAGE_ENTRY_POINTS.ESSENTIALS:
      return {
        title: formatMessage(EMAIL_CAPTURE_PAGE_TITLE[EmailCaptureVariant.SELF_CARE]),
        illustration: <RegistrationSelfCarePathwayIllustration />,
      }
    default:
      return {
        title: formatMessage(EMAIL_CAPTURE_PAGE_TITLE[EmailCaptureVariant.GENERAL]),
        illustration: <RegistrationGeneralPathwayIllustration />,
      }
  }
}

export interface RegistrationVariantEmailCaptureFormProps {
  onSignUpButtonPress: (values: EmailCaptureFormValues) => void
  onSignInLinkPress: () => void
  onBackButtonPress?: () => void
  appleSSOAvailable?: boolean
  googleSSOAvailable?: boolean
  onAppleSignUpButtonPress?: () => void
  onGoogleSignUpButtonPress?: () => void
  isGenerateVerificationTokenLoading?: boolean
  customAppleSSOContent?: React.ReactNode
  customGoogleSSOContent?: React.ReactNode
  reCaptchaContent?: React.ReactNode
  isEmailError?: boolean
  routeOrigin?: string
}

export const RegistrationVariantEmailCaptureForm: FunctionComponent<RegistrationVariantEmailCaptureFormProps> = ({
  appleSSOAvailable,
  googleSSOAvailable,
  onAppleSignUpButtonPress = noop,
  onGoogleSignUpButtonPress = noop,
  onBackButtonPress,
  onSignInLinkPress,
  onSignUpButtonPress,
  isGenerateVerificationTokenLoading,
  customAppleSSOContent,
  customGoogleSSOContent,
  reCaptchaContent,
  isEmailError,
  routeOrigin,
}) => {
  const name = 'RegistrationVariantEmailCaptureForm'
  const isSsoAvailable = appleSSOAvailable || googleSSOAvailable || customAppleSSOContent || customGoogleSSOContent
  const { formatMessage } = useIntl()
  const { colors, breakpoints } = useTheme()

  const { title, illustration } = getVariantDataByPathway(formatMessage, routeOrigin as TRIAGE_ENTRY_POINTS)

  const body = (
    <>
      <IllustrationContainer>{illustration}</IllustrationContainer>
      <EmailFormContainer testID={tID(`${name}-formContainer`)}>
        <Form
          onSubmit={onSignUpButtonPress}
          initialValues={{ emailAddress: '' }}
          validate={(formValues) => validateEmailForm(formValues, formatMessage)}
          render={({ handleSubmit, errors, touched }) => (
            <FormContainer handleSubmit={handleSubmit}>
              <HeaderContainer size={SubheadSize.LARGE} text={title} textAlign={'center'} testID={`${name}-title`} />

              {isEmailError && <InlineErrorBanner text={formatMessage(genericErrorMessage)} />}
              <Field
                testID={`${name}-emailAddressField`}
                name='emailAddress'
                label={formatMessage({
                  defaultMessage: 'Personal email',
                  description: 'Label of field for the user to enter their personal email address',
                })}
                component={InputFieldRFF}
                baseInputStyle={{ marginBottom: 16 }}
                inputContainerStyle={{ borderRadius: 8 }}
                inputAccessoryViewID={name}
                returnKeyType='done'
                error={touched && errors?.emailAddress}
              />
              <ButtonGroupContainer>
                <PrimaryButton
                  loading={!!isGenerateVerificationTokenLoading}
                  fullWidth
                  testID={tID(`${name}-defaultContinueButton`)}
                  text={formatMessage({
                    defaultMessage: 'Continue',
                    description: 'Button that allows user to continue',
                  })}
                  onPress={handleSubmit}
                />
                {isSsoAvailable && (
                  <DividerContainer>
                    <SplitDivider />
                    <DividerText
                      text={<FormattedMessage defaultMessage='or' description='The word "or"' />}
                      size={BodyTextSize.SMALL}
                    />
                    <SplitDivider />
                  </DividerContainer>
                )}
                {googleSSOAvailable && (
                  <ButtonContainer addMarginBottom={appleSSOAvailable}>
                    <SsoButton
                      testId={tID('Registration-GoogleButton')}
                      type={SSO_BUTTON_TYPE.GOOGLE}
                      messageType={SSO_BUTTON_MESSAGE_TYPE.CONTINUE}
                      onPress={onGoogleSignUpButtonPress}
                    />
                  </ButtonContainer>
                )}
                {customGoogleSSOContent}
                {appleSSOAvailable && (
                  <ButtonContainer>
                    <SsoButton
                      testId={tID('Registration-AppleButton')}
                      type={SSO_BUTTON_TYPE.APPLE}
                      messageType={SSO_BUTTON_MESSAGE_TYPE.CONTINUE}
                      onPress={onAppleSignUpButtonPress}
                    />
                  </ButtonContainer>
                )}
                {customAppleSSOContent}
              </ButtonGroupContainer>
            </FormContainer>
          )}
        />
      </EmailFormContainer>
      <FooterTextContainer>
        <AlreadyHaveAccountText
          text={
            <FormattedMessage
              defaultMessage='Already have an account? <link>Log In</link>'
              description='Message on registration page prompting users to sign in with an existing account'
              values={{
                link: (text: string) => (
                  <Link testID={tID(`${name}-signInLink`)} onPress={onSignInLinkPress} text={text} underline />
                ),
              }}
            />
          }
        />
      </FooterTextContainer>
    </>
  )

  return (
    <>
      <Flex1Container testID={tID(name)}>
        <StickyView
          headerBackgroundColor={!IS_WEB ? colors.backgroundTransparent : undefined}
          headerComponent={
            !!onBackButtonPress && <NavBar isTransparent={!IS_WEB} showBackButton onBack={onBackButtonPress} />
          }
          safeAreaInsets={IS_WEB && breakpoints.isMobileSized ? { top: 30, bottom: 0 } : { bottom: 0 }}
        >
          {body}
          {!IS_WEB && <RecaptchaContainer lessMargin={!!appleSSOAvailable}>{reCaptchaContent}</RecaptchaContainer>}
        </StickyView>
        <InputAccessory nativeID={name} />
      </Flex1Container>
      {IS_WEB && reCaptchaContent}
    </>
  )
}
