import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import {
  CostMessagingCoverageType,
  CostMessagingModalContent,
  PathwayOptions,
  TEEN_ENABLED_PATHWAYS,
} from '@lyrahealth-inc/shared-app-logic'

import { LoadingIndicator } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import * as Illustrations from '../../atoms/illustrations/index'
import {
  ForkedTriageCareOptionCard,
  ForkedTriageCareOptionsMetadata,
  ForkedTriageCareOptionsOUSMetadata,
} from '../../careOptionsMetadata/forkedTriageMetadata'
import { MedsConsultModal } from '../../molecules'
import { LatestMatchesLinkProps } from '../../molecules/latestMatchesLink/LatestMatchesLink'
import { CareOptions } from '../../organisms'
import { CareOptionCardProps } from '../../templates/careOptionCard/CareOptionCard'

export interface ForkedTriagePageProps {
  showChildSearch: boolean
  showCoachingToolkit: boolean
  showParentingToolkit: boolean
  showMedsConsult: boolean
  isInternational: boolean
  isDirectAccessMedsSupported: boolean
  onSelfSearchPress: () => void
  onChildSearchPress?: () => void
  onCoachingToolkitPress?: () => void
  onParentingToolkitPress?: () => void
  onMedConsultPress?: () => void
  onLearnMorePress?: () => void
  latestMatchesLinkProps?: LatestMatchesLinkProps
  onContactCareNavigatorPress?: () => void
  careNavigatorFooterEntryPointModal?: React.ReactNode
  isUserTeen?: boolean
  coverageModalContents?: CostMessagingModalContent[]
  costMessagingCoverageType?: CostMessagingCoverageType
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
  loading: boolean
}

export const ForkedTriagePage: FunctionComponent<ForkedTriagePageProps> = ({
  showChildSearch,
  showCoachingToolkit,
  showParentingToolkit,
  showMedsConsult,
  isInternational,
  isDirectAccessMedsSupported,
  onSelfSearchPress,
  onChildSearchPress = noop,
  onCoachingToolkitPress = noop,
  onParentingToolkitPress = noop,
  onMedConsultPress = noop,
  onLearnMorePress = noop,
  latestMatchesLinkProps,
  onContactCareNavigatorPress = noop,
  careNavigatorFooterEntryPointModal,
  isUserTeen = false,
  coverageModalContents = [],
  costMessagingCoverageType = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  loading,
}) => {
  const { breakpoints, spacing } = useTheme()
  const { formatMessage } = useIntl()
  const careNavigatorLinkTestId = 'ForkedTriage-ContactCareNavigatorLink'

  const getTriageCareOptionMetadata: (option: PathwayOptions) => Partial<ForkedTriageCareOptionCard> | undefined = (
    option,
  ) => {
    if (option === PathwayOptions.CHILD_SEARCH) {
      return isInternational ? ForkedTriageCareOptionsOUSMetadata[option] : ForkedTriageCareOptionsMetadata[option]
    }
    return ForkedTriageCareOptionsMetadata[option]
  }

  const forkedTriageOptions: {
    option: PathwayOptions
    shouldShow: boolean
    cardProps: Partial<ForkedTriageCareOptionCard>
  }[] = [
    {
      option: PathwayOptions.SELF_SEARCH,
      shouldShow: true,
      cardProps: {
        onCardPress: onSelfSearchPress,
        latestMatchesLinkProps,
      },
    },
    {
      option: PathwayOptions.CHILD_SEARCH,
      shouldShow: showChildSearch,
      cardProps: { onCardPress: onChildSearchPress },
    },
    {
      option: PathwayOptions.COACHING_TOOLKIT,
      shouldShow: showCoachingToolkit,
      cardProps: { onCardPress: onCoachingToolkitPress },
    },
    {
      option: PathwayOptions.PARENTING_TOOLKIT,
      shouldShow: showParentingToolkit,
      cardProps: { onCardPress: onParentingToolkitPress },
    },
    {
      option: PathwayOptions.MEDICATION_CONSULTATION,
      shouldShow: showMedsConsult,
      cardProps: {
        onCardPress: onMedConsultPress,
        descriptionFormattedMessageValues: {
          medsConsultModal: () => (
            <MedsConsultModal
              isDirectAccessMedsSupported={isDirectAccessMedsSupported}
              onBookConsultationPress={onMedConsultPress}
              onLearnMorePress={onLearnMorePress}
            />
          ),
        },
      },
    },
  ]

  const shouldShowForTeen = (option: PathwayOptions) => {
    return isUserTeen ? TEEN_ENABLED_PATHWAYS.includes(option) : true
  }

  const forkedTriageOptionsDisplayed: CareOptionCardProps[] = forkedTriageOptions
    .filter((item) => item.shouldShow && shouldShowForTeen(item.option))
    .map((item) => {
      const forkedTriageCardProps = {
        ...item.cardProps,
        ...getTriageCareOptionMetadata(item.option),
      } as ForkedTriageCareOptionCard
      const IconComponent = Illustrations[forkedTriageCardProps.icon]

      return {
        customHeaderText: <BodyText size={BodyTextSize.DEFAULT} text={formatMessage(forkedTriageCardProps.header)} />,

        optionDescription:
          forkedTriageCardProps.description &&
          formatMessage(forkedTriageCardProps.description, {
            ...forkedTriageCardProps.descriptionFormattedMessageValues,
          }),

        optionIcon: <IconComponent size={breakpoints.isMobileSized ? 48 : 56} />,
        onPress: forkedTriageCardProps.onCardPress,
        testId: `ForkedTriageCareOptionCard-${forkedTriageCardProps.testId}`,
        customStyle: {
          padding: breakpoints.isMobileSized
            ? `${spacing['12px']} ${spacing['16px']}`
            : `${spacing['20px']} ${spacing['16px']}`,
          borderRadius: spacing['16px'],
        },
        chevronSize: 24,
        latestMatchesLinkProps: forkedTriageCardProps.latestMatchesLinkProps,
      }
    })

  return loading ? (
    <LoadingIndicator />
  ) : (
    <CareOptions
      careOptionsList={forkedTriageOptionsDisplayed}
      careNavigatorFooterEntryPointModal={careNavigatorFooterEntryPointModal}
      onContactCareNavigatorPress={onContactCareNavigatorPress}
      careNavigatorLinkTestId={careNavigatorLinkTestId}
      coverageModalContents={coverageModalContents}
      costMessagingCoverageType={costMessagingCoverageType}
      isCostMessagingEnabled={isCostMessagingEnabled}
      trackCoverageModalPress={trackCoverageModalPress}
    />
  )
}
