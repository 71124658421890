import { useMemo } from 'react'

import { AxiosResponse } from 'axios'

import {
  COMMON_MIXPANEL_PAGE,
  Episode,
  getWellnessToolkitAvailableConfig,
  RiskFactorsResponse,
  SearchRequestData,
  TriageSearch,
  User,
  useRunSpeculativeSearch,
  WELLNESS_TOOLKIT_TYPE,
} from '@lyrahealth-inc/shared-app-logic'

import {
  isLatestCompletedSearchNotTierThree,
  isNotInActiveEpisode as isNotInActiveEpisodeCheck,
  isNotSelfHarmOrSuicialIdeationIndicated,
} from '../wellnessToolkitUtil'

export interface useIsWellnessToolkitAvailableParams {
  toolkitType: WELLNESS_TOOLKIT_TYPE
  page?: COMMON_MIXPANEL_PAGE

  lyraId: string
  isUserTeen: boolean
  triageSearches: TriageSearch[]
  episodes: Episode[]
  isUserLoggedIn: boolean
  isInternationalUser: boolean
  postSpeculativeSearch: (lyraId: string, searchData: SearchRequestData) => Promise<AxiosResponse<boolean>>
  isLctTeensEnabled: boolean
  isCoachingEnabled: boolean
  riskFactors?: RiskFactorsResponse
  userDOB?: string
  userCountryName?: string
  isEssentialsEnabled: boolean
  hideDependentField?: boolean
  hideChildSearchCTA?: boolean
  hasIndicatedIsGuardian?: boolean
  userChildren?: User[]
  isFamilyHubEnabled?: boolean
  isCoachingEntryPointEnabled?: boolean
  useFetchChildrenHook: (disableFetch?: boolean, forceFetch?: boolean) => { isLoading: boolean }
}

/**
 * Use the app specific version of this hook.
 *
 * Conditions for when a toolkit is available: https://lyrahealth.atlassian.net/wiki/spaces/EN/pages/3448995850/Wellness+Toolkits+Coaching+Parenting+Family+Hub
 * @param toolkitType
 * @param page Where the toolkit link is being shown. Defaults to homebase carousel
 *
 * The remaining params are all app specific.
 *
 * @returns `isToolkitAvailable` - if toolkit can be shown
 * @returns `isLoading` - if still evaluating the conditions
 */
export const useIsWellnessToolkitAvailable = ({
  toolkitType,
  page,
  lyraId,
  isUserTeen,
  triageSearches,
  isCoachingEnabled,
  riskFactors,
  episodes,
  isUserLoggedIn,
  isInternationalUser,
  postSpeculativeSearch,
  isLctTeensEnabled,
  userDOB,
  userCountryName,
  isEssentialsEnabled,
  hideDependentField,
  hideChildSearchCTA,
  hasIndicatedIsGuardian,
  userChildren,
  isFamilyHubEnabled,
  isCoachingEntryPointEnabled,
  useFetchChildrenHook,
}: useIsWellnessToolkitAvailableParams) => {
  const conditions = useMemo(() => {
    return getWellnessToolkitAvailableConfig(toolkitType, page)
  }, [page, toolkitType])
  const { configExtras } = conditions

  let isFeatureFlagEnabled: boolean | undefined = false
  if (toolkitType === WELLNESS_TOOLKIT_TYPE.FAMILY_HUB) {
    isFeatureFlagEnabled = isFamilyHubEnabled
  } else if (
    toolkitType === WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING ||
    toolkitType === WELLNESS_TOOLKIT_TYPE.PARENT_COACHING
  ) {
    isFeatureFlagEnabled = isCoachingEntryPointEnabled
  }

  /** Evaluate the higher level conditions so we can short circuit the logic that is more expensive, like speculative search */
  const isAvailable = useMemo(() => {
    return (
      (conditions.shouldFeatureFlagBeEnabled ? isFeatureFlagEnabled : true) &&
      (conditions.shouldEssentialsBeEnabled ? isEssentialsEnabled : true) &&
      (conditions.shouldCoachingBeEnabled ? isCoachingEnabled : true) &&
      (conditions.shouldDependentsBeSupported ? !hideChildSearchCTA && !hideDependentField : true) &&
      (conditions.shouldNotBeTeen ? !isUserTeen : true) &&
      (conditions.shouldNotBeInternational ? !isInternationalUser : true)
    )
  }, [
    conditions.shouldCoachingBeEnabled,
    conditions.shouldDependentsBeSupported,
    conditions.shouldEssentialsBeEnabled,
    conditions.shouldFeatureFlagBeEnabled,
    conditions.shouldNotBeInternational,
    conditions.shouldNotBeTeen,
    hideChildSearchCTA,
    hideDependentField,
    isCoachingEnabled,
    isEssentialsEnabled,
    isFeatureFlagEnabled,
    isInternationalUser,
    isUserTeen,
  ])

  // Speculative search (skip if programSearchRequestData is undefined)
  const shouldHaveProviderSupply = conditions.shouldHaveProviderSupply && isUserLoggedIn
  const program = configExtras?.programSearchRequestData
  const { clientele, treatment, partner, offering, directLinkIntent } = program ?? {}
  const searchRequestData = useMemo(() => {
    return isAvailable && program && shouldHaveProviderSupply
      ? { clientele, treatment, partner, offering, directLinkIntent }
      : undefined
  }, [clientele, directLinkIntent, isAvailable, offering, partner, program, shouldHaveProviderSupply, treatment])
  const speculativeSearchResult = useRunSpeculativeSearch({
    searchRequestData,
    isUserLoggedIn,
    lyraId,
    userDOB,
    userCountryName,
    isInternationalUser,
    postSpeculativeSearch,
    isLctTeensEnabled,
  })
  const hasAvailableProviderSupply = speculativeSearchResult.hasAvailableSupply

  const shouldFetchChildren = isAvailable && conditions.shouldBeKnownParent
  const isLoadingChildren = useFetchChildrenHook(!shouldFetchChildren, true).isLoading

  const isKnownParent =
    hasIndicatedIsGuardian ||
    userChildren?.length ||
    (configExtras?.triageConditionsChecker && configExtras?.triageConditionsChecker(triageSearches))

  const isNotSHOrSI = isNotSelfHarmOrSuicialIdeationIndicated(riskFactors)

  const isNotT3Recommendation = isLatestCompletedSearchNotTierThree(triageSearches)

  const isNotInActiveEpisode = isNotInActiveEpisodeCheck(
    episodes,
    lyraId,
    configExtras?.allowedProgramsForActiveEpisodes,
  )

  return useMemo(() => {
    return {
      /** <if condition applies> ? <evaluate condition> : true (skip condition) */
      isToolkitAvailable: Boolean(
        isAvailable &&
          (conditions.shouldNotBeSHOrSI ? isNotSHOrSI : true) &&
          (conditions.shouldNotBeInActiveEpisode ? isNotInActiveEpisode : true) &&
          (conditions.shouldNotHaveT3Recommendation ? isNotT3Recommendation : true) &&
          (conditions.shouldBeKnownParent ? isKnownParent : true) &&
          (shouldHaveProviderSupply ? hasAvailableProviderSupply : true),
      ),
      isLoading: speculativeSearchResult.isLoading || isLoadingChildren,
    }
  }, [
    isAvailable,
    conditions.shouldNotBeSHOrSI,
    conditions.shouldNotBeInActiveEpisode,
    conditions.shouldNotHaveT3Recommendation,
    conditions.shouldBeKnownParent,
    isNotSHOrSI,
    isNotInActiveEpisode,
    isNotT3Recommendation,
    isKnownParent,
    shouldHaveProviderSupply,
    hasAvailableProviderSupply,
    speculativeSearchResult.isLoading,
    isLoadingChildren,
  ])
}
