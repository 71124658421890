import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { CostMessagingCoverageType, CostMessagingModalContent } from '@lyrahealth-inc/shared-app-logic/build/src'

import {
  BodyText,
  LoadingIndicator,
  PrimaryButton,
  ShieldTriageIcon,
  Subhead,
  Tooltip,
  TOOLTIP_TRIGGER_ICON,
} from '../../atoms'
import { IS_WEB } from '../../constants'
import { CareNavigatorCareOptionsFooter } from '../../molecules'
import { CoverageMessagingLink } from '../../molecules/coverageMessagingLink/CoverageMessagingLink'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ColumnView, RowView } from '../../templates'
import { CareOptionCard, CareOptionCardProps } from '../../templates/careOptionCard/CareOptionCard'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface CareOptionsProps {
  careOptionsList: CareOptionCardProps[]
  mainCareOption?: CareOptionCardProps
  header?: string
  showPrivacy?: boolean
  careNavigatorFooterEntryPointModal?: React.ReactNode
  onContactCareNavigatorPress?: () => void
  careNavigatorLinkTestId?: string
  coverageModalContents?: CostMessagingModalContent[]
  costMessagingCoverageType?: CostMessagingCoverageType
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
  isLoading?: boolean
}

const Content = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  maxWidth: theme.breakpoints.isMobileSized ? 'auto' : '750px',
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['32px'],
  paddingHorizontal: theme.spacing['16px'],
  marginHorizontal: 'auto',
  marginBottom: theme.spacing['64px'],
  width: '100%',
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const PrivacyContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: theme.spacing['12px'],
  alignItems: 'center',
  marginHorizontal: theme.breakpoints.isMobileSized ? 'auto' : 0,
}))

const StyledLockedIconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

const CareOptionsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['32px'],
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  flexWrap: 'wrap',
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['0px'] : '-16px',
}))

const CardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
  width: theme.breakpoints.isMobileSized ? '100%' : '351px',
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['0px'] : theme.spacing['16px'],
}))

const MainCareOptionCard = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: theme.spacing['20px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
  width: '100%',
  padding: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : '44px 24px',
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
}))

const MainCareOptionHeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const MainCareOptionBodyContainer = styled(ColumnView)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.breakpoints.isMobileSized ? 0 : theme.spacing['32px'],
  flex: 'auto',
  ...(theme.breakpoints.isMobileSized ? { marginTop: theme.spacing['16px'] } : { marginLeft: theme.spacing['32px'] }),
  alignItems: theme.breakpoints.isMobileSized ? 'center' : 'flex-start',
  justifyContent: 'center',
}))

const MainCareOptionIllustrationContainer = styled.View({
  alignSelf: 'center',
})

const MainCareOptionCta = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
  width: '100%',
  ...(!theme.breakpoints.isMobileSized && { maxWidth: '203px' }),
}))

const CoverageLinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
}))

const TooltipStyled = styled(Tooltip)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['8px'],
}))

const LoadingContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['48px'],
  paddingBottom: theme.spacing['64px'],
}))

export const CareOptions: FunctionComponent<CareOptionsProps> = ({
  careOptionsList,
  mainCareOption,
  header,
  showPrivacy = true,
  careNavigatorFooterEntryPointModal,
  onContactCareNavigatorPress = noop,
  careNavigatorLinkTestId = 'CareOptions-ContactCareNavigatorLink',
  coverageModalContents = [],
  costMessagingCoverageType = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
  isLoading,
}) => {
  const { colors } = useTheme() as ThemeType

  return (
    <>
      <Content testID={tID('CareOptions')}>
        <HeaderContainer>
          <Subhead
            text={
              header || (
                <FormattedMessage
                  defaultMessage='How can we help?'
                  description='Header text to ask user how we can help'
                />
              )
            }
            size={SubheadSize.LARGE}
            textAlign='center'
          />
          <CoverageLinkContainer>
            <CoverageMessagingLink
              coverageModalContents={coverageModalContents}
              costMessagingCoverageType={costMessagingCoverageType}
              isCostMessagingEnabled={isCostMessagingEnabled}
              trackCoverageModalPress={trackCoverageModalPress}
            />
          </CoverageLinkContainer>
        </HeaderContainer>

        {mainCareOption && (
          <MainCareOptionCard testID={tID(mainCareOption.testId)}>
            <MainCareOptionIllustrationContainer>{mainCareOption.optionIcon}</MainCareOptionIllustrationContainer>
            <MainCareOptionBodyContainer>
              {mainCareOption.optionHeader && (
                <MainCareOptionHeaderContainer>
                  <Subhead text={mainCareOption.optionHeader} size={SubheadSize.MEDIUM} />
                </MainCareOptionHeaderContainer>
              )}
              <RowView>
                {mainCareOption.optionDescription && (
                  <BodyText
                    text={mainCareOption.optionDescription}
                    size={BodyTextSize.DEFAULT}
                    color={colors.textSecondary}
                  />
                )}
                {mainCareOption.tooltipContent && (
                  <TooltipStyled
                    triggerConfig={{
                      icon: TOOLTIP_TRIGGER_ICON.INFO,
                      size: 16,
                      isFilled: false,
                    }}
                    content={mainCareOption.tooltipContent}
                    hoverEnabled={IS_WEB}
                  />
                )}
              </RowView>
              <MainCareOptionCta
                text={mainCareOption.ctaText}
                onPress={mainCareOption.onPress || noop}
                testID={tID(`${mainCareOption.testId}Cta`)}
              />
            </MainCareOptionBodyContainer>
          </MainCareOptionCard>
        )}

        {isLoading ? (
          <LoadingContainer>
            <LoadingIndicator topPadding={0} />
          </LoadingContainer>
        ) : (
          <CareOptionsContainer>
            {careOptionsList.map((item, index) => {
              return (
                <CardContainer key={index}>
                  <CareOptionCard {...item} useBorderStyles />
                </CardContainer>
              )
            })}
          </CareOptionsContainer>
        )}
        <CareNavigatorCareOptionsFooter
          careNavigatorLinkTestId={careNavigatorLinkTestId}
          onContactCareNavigatorPress={onContactCareNavigatorPress}
        />
        {showPrivacy && (
          <PrivacyContainer>
            <StyledLockedIconContainer>
              <ShieldTriageIcon size={24} />
            </StyledLockedIconContainer>
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='Responses are confidential'
                  description='Description text explaining that responses are confidential'
                />
              }
              size={BodyTextSize.BADGE}
              textAlign='center'
              color={colors.textSecondary}
              bold
            />
          </PrivacyContainer>
        )}
      </Content>
      {careNavigatorFooterEntryPointModal}
    </>
  )
}
