import { defineMessage } from 'react-intl'
import { NavigateFunction } from 'react-router'

import { AxiosResponse } from 'axios'
import { fromJS, List, Map } from 'immutable'
import { cloneDeep, get, isEmpty } from 'lodash-es'
import { Dispatch } from 'redux'

import {
  defaultV2PreferencesForUser,
  MEETING_FORMATS,
  MemberPreferencesForUserV2,
  PostLatestProviderResults,
  ProviderInfo,
  SEARCHER_TYPE_TO_MATCH_FOR,
  SearchProviders,
  SearchRequestData,
  TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND,
  TriageSearch,
} from '@lyrahealth-inc/shared-app-logic'

import { getUserMemberPreferencesV2 } from './onboardSelectors'
import { changeRoute, PAGE_ROUTES } from './page-navigation/location-actions'
import { getTriageDemographicMatchFor } from './triage-demographics/triageDemographicsSelectors'
import * as BannerActions from '../../../common/components/banner/banner-actions'
import { axiosInstance } from '../../../common/http/axiosInstance'
import {
  getLatestUserFavoriteProviders,
  getLocationSearchCountData,
  getMemberPreferencesForUserV2,
  getProviderRecommendationsData,
  getProviderRecommendationsDirectBookingData,
  submitMemberPreferencesForUserV2,
} from '../../../common/http/data/onboard'
import { getProvidersMatchingGivenAppointmentModalities } from '../../../common/utils/providerUtils'
import { getUserPreferredAppointmentTypes } from '../../../data/user/userSelectors'
import { getLatestUserFavoriteProviders as getLatestUserFavoriteProvidersSelector } from '../../../features/onboard/data/onboardSelectors'
import { getChildLyraId } from '../../triage/data/triageSelectors'
import { setMemberPreferencesForUserV2 } from '../carePreferences/data/carePreferencesActions'
import { clearProviderProfileData, setProvider } from '../providerProfile/data/providerProfileActions'
import { setFallbackTreatmentOption } from '../treatmentOptions/data/treatmentOptionsActions'

export const GET_PROVIDER_RECOMMENDATIONS = 'GET_PROVIDER_RECOMMENDATIONS'
export const SET_ONBOARD_PROGRESS = 'SET_ONBOARD_PROGRESS'
export const GET_SEARCH_COUNT = 'GET_SEARCH_COUNT'
export const SET_TIMEZONE_FOR_AVAILABILITY_REQUEST = 'SET_TIMEZONE_FOR_AVAILABILITY_REQUEST'
export const SET_MEETING_FORMAT = 'SET_MEETING_FORMAT'
export const SET_USER_FAVORITE_PROVIDER_REQUEST_ID = 'SET_USER_FAVORITE_PROVIDER_REQUEST_ID'
export const DUPLICATE_TRIAGE_INFO = 'DUPLICATE_TRIAGE_INFO'
export const SAVE_TRIAGE_INFO = 'SAVE_TRIAGE_INFO'
export const SET_USER_MEMBER_PREFERENCES = 'SET_USER_MEMBER_PREFERENCES'
export const GET_LATEST_USER_FAVORITE_PROVIDERS = 'GET_LATEST_USER_FAVORITE_PROVIDERS'
export const SET_LATEST_MATCHES_SEARCH = 'SET_LATEST_MATCHES_SEARCH'
export const SET_LOADING_LATEST_PROVIDER_MATCHES = 'SET_LOADING_LATEST_PROVIDER_MATCHES'
export const CLEAR_DISPLAYED_PROVIDERS = 'CLEAR_DISPLAYED_PROVIDERS'
export const SET_IS_CURRENT_SEARCH_DIRECT_PATH = 'SET_IS_CURRENT_SEARCH_DIRECT_PATH'
export const SET_DIRECT_LINK_INTENT = 'SET_DIRECT_LINK_INTENT'
export const CLEAR_DIRECT_LINK_INTENT = 'CLEAR_DIRECT_LINK_INTENT'
export const SET_DIRECT_LINK_SOURCE = 'SET_DIRECT_LINK_SOURCE'
export const CLEAR_DIRECT_LINK_SOURCE = 'CLEAR_DIRECT_LINK_SOURCE'

export const setOnboardProgress = (value: $TSFixMe) => {
  return {
    type: SET_ONBOARD_PROGRESS,
    payload: value,
  }
}

export const setTimeZoneForAvailabilityRequest = (timeZone: $TSFixMe) => {
  return {
    type: SET_TIMEZONE_FOR_AVAILABILITY_REQUEST,
    timeZone,
  }
}

export const setUserFavoriteProviderRequestID = (value: $TSFixMe) => {
  return {
    type: SET_USER_FAVORITE_PROVIDER_REQUEST_ID,
    payload: value,
  }
}

export const getLocationSearchCount = (id: string) => {
  const request = getLocationSearchCountData(id)
  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (countReturn) => {
          dispatch({
            type: GET_SEARCH_COUNT,
            payload: countReturn.data,
          })

          resolve(countReturn)
        },
        (error) => {
          reject(error)
        },
      )
    })
  }
}

/**
 * Called by the standard flow of going through triage and getting provider results
 * and also called by latest provider matches when we fetch the list of user favorite providers
 * and set them on the provider results page
 */
export const handleProviderRecommendationsResults = (
  recommendationsData: SearchProviders,
  $$preferredAppointmentTypes: List<string>,
  treatmentPreference?: string,
) => {
  return (dispatch: Dispatch<$TSFixMe>) => {
    const { providerResults, providersDisplayed, userFavoriteProviderRequestId, providerResultsTreatmentPreference } =
      recommendationsData

    dispatch(setUserFavoriteProviderRequestID(userFavoriteProviderRequestId))

    // parse data
    const $$providersData = fromJS(providerResults)
    const providersForMatching: ProviderInfo[] = []
    const $$availabilities = $$providersData.getIn(['providerAvailabilities'], fromJS([]))

    // Specific to BC-Meds search, we perform an automatic fallback to DA Meds results when 0 BCM providers are returned.
    // This block checks if the treatment option in the original search requests matches the treatment option returned
    // in the provider results. If it doesn't, we know that the automatic fallback occured and we need to update the
    // selected treatment option for appointment booking to work correctly and identify as a non BC program.
    if (
      treatmentPreference === TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.BLENDED_CARE_MEDS &&
      providerResultsTreatmentPreference === TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.DIRECT_ACCESS_MEDS
    ) {
      dispatch(setFallbackTreatmentOption(providerResultsTreatmentPreference))
    } else {
      dispatch(setFallbackTreatmentOption(null))
    }

    // The providerObj here can be a Lyra Provider object, i.e. ProviderInfo, or it can be an object
    // of type { bucketName, providerList }. This ensures backwards compatibility after we move
    // away from bucketing and the FE can handle both data types without reliance on the BE versioning
    const providersDisplayedWithInjectedAvailability = providersDisplayed.map((providerObj: $TSFixMe) => {
      // inject availability as a property on each provider object in providersDisplayed
      const injectAvailability = (providerWithAvailability: ProviderInfo) => {
        const id = providerWithAvailability.lyra_id
        const $$availability = $$availabilities.find(
          ($$available: $TSFixMe) => {
            return id === $$available.get('id', '')
          },
          null,
          List(),
        )
        providerWithAvailability.calendar = $$availability
        providersForMatching.push(providerWithAvailability)
        return providerWithAvailability
      }

      const providerWithAvailability: ProviderInfo = JSON.parse(JSON.stringify(providerObj))
      return injectAvailability(providerWithAvailability)
    }, List())

    const $$providers = fromJS(providersForMatching)
    const $$numberOfNonTopProviders = $$providers.size > 1 ? $$providers.size - 1 : 0
    const $$matchingProviders = getProvidersMatchingGivenAppointmentModalities({
      providers: $$providers,
      modalities: $$preferredAppointmentTypes,
    })
    dispatch({
      type: GET_PROVIDER_RECOMMENDATIONS,
      totalNumberOfProviders: $$providers.size,
      numberOfNonTopProviders: $$numberOfNonTopProviders,
      numberOfProvidersMatchingModalityPreference: $$matchingProviders.size,
      providersDisplayed: providersDisplayedWithInjectedAvailability,
    })

    dispatch(setTimeZoneForAvailabilityRequest($$availabilities.getIn([0, 'timeZoneId'])))

    dispatch(clearProviderProfileData())
  }
}

export const getProviderRecommendations = ({
  userId,
  searchRequest,
  $$preferredAppointmentTypes,
  isDirectPath,
}: {
  userId: string
  searchRequest: SearchRequestData
  $$preferredAppointmentTypes: List<string>
  isDirectPath?: boolean
}): ((dispatch: Dispatch<$TSFixMe>, getState: () => Map<string, any>) => Promise<AxiosResponse<SearchProviders>>) => {
  return (dispatch: Dispatch<$TSFixMe>, getState: () => Map<string, any>) => {
    const state = getState()
    const matchFor = getTriageDemographicMatchFor(state)
    const request = isDirectPath
      ? getProviderRecommendationsDirectBookingData(userId, { searchRequest })
      : getProviderRecommendationsData(userId, {
          searchRequest,
          childLyraId: matchFor === SEARCHER_TYPE_TO_MATCH_FOR.OTHER_CHILD ? getChildLyraId(state) : undefined,
        })

    return request.then(
      (recommendationsReturn) => {
        dispatch(
          handleProviderRecommendationsResults(
            recommendationsReturn.data,
            $$preferredAppointmentTypes,
            searchRequest.treatmentPreference,
          ),
        )
        return Promise.resolve(recommendationsReturn)
      },
      (error) => {
        dispatch(
          BannerActions.setBannerPropsAndStatus(
            BannerActions.BANNER_TYPE.DANGER,
            defineMessage({
              defaultMessage: 'An error occurred in getting your recommendations. Please try again.',
              description: 'Error message when getting provider recommendations fails',
            }),
            true,
          ),
        )
        return Promise.reject(error)
      },
    )
  }
}

export const setMeetingFormat = (value: $TSFixMe) => {
  return {
    type: SET_MEETING_FORMAT,
    value,
  }
}

export const saveMemberPreferenceForUserV2 = ({
  lyraId,
  data,
}: {
  lyraId: string
  data: MemberPreferencesForUserV2
}) => {
  return (dispatch: Dispatch) => {
    dispatch(setMemberPreferencesForUserV2(data))

    // Update the store with the new preferences blob created in this function
    const request = submitMemberPreferencesForUserV2(lyraId, data)
    return new Promise(function (resolve, reject) {
      return request.then(
        (submissionReturn) => {
          return resolve(submissionReturn)
        },
        (submissionError) => {
          return reject(submissionError)
        },
      )
    })
  }
}

export const fetchMemberPreferencesForUserV2 = ({
  userId,
  searchId,
  isCoachingWithLMSearch,
}: {
  userId: string
  searchId: string
  isCoachingWithLMSearch: boolean
}) => {
  return (dispatch: $TSFixMe, getState: () => any) => {
    return new Promise((resolve, reject) => {
      return new Promise((resolve) => {
        // Check the store for preferences and return if found
        const foundPreferences = getUserMemberPreferencesV2(getState())
        if (foundPreferences) {
          return resolve({
            data: foundPreferences,
          })
        } else {
          // Preferences not found in local store, so request LW API to retrieve saved preferences for the current user
          // LW API will not return a user's saved preferences if age of the saved preferences exceeds 7 days.
          // After 7 days, these preferences are considered "expired" and we do not prefill their saved selections on the UI.
          return getMemberPreferencesForUserV2(userId)
            .then((preferencesReturn) => resolve(preferencesReturn))
            .catch((err) => {
              // Resolving null here is akin to no preferences being fetched from the BE. The .then() block followup will evaluate that
              // no preferences were fetched, and set the user's preference to the default state.
              console.error('Failed to fetch saved preferences for user', err)
              return resolve({
                data: null,
              })
            })
        }
      }).then(
        async (savedMemberPreferences) => {
          // Default Preferences
          const defaultPreferences = cloneDeep(defaultV2PreferencesForUser)

          // Data will hold the preferences if there were any returned from the BE -- if not, default to all false preferences, aka clean slate
          let preferences: any = get(savedMemberPreferences, 'data')

          if (isEmpty(preferences)) {
            preferences = defaultPreferences
          }

          // Only set the meeting format if the current search is for coaching with option for live messaging
          // Otherwise, the meeting format doesn't need to be set because it's either
          // defaulting to video in non-LM coach searches, or isn't set for other treatment options
          if (isCoachingWithLMSearch) {
            const preferredSessionFormat: string[] = preferences.preferredSessionFormat
            if (preferredSessionFormat.length > 1) dispatch(setMeetingFormat(MEETING_FORMATS.VIDEO_AND_LIVE_MESSAGING))
            else if (preferredSessionFormat.includes(MEETING_FORMATS.VIDEO))
              dispatch(setMeetingFormat(MEETING_FORMATS.VIDEO))
            else if (preferredSessionFormat.includes(MEETING_FORMATS.LIVE_MESSAGING))
              dispatch(setMeetingFormat(MEETING_FORMATS.LIVE_MESSAGING))
            else dispatch(setMeetingFormat(MEETING_FORMATS.NONE_SPECIFIED))
          }

          // Update the saved preferences in the store for future fetches so we don't have the continually query API
          dispatch(setMemberPreferencesForUserV2(preferences))

          return resolve(preferences)
        },
        (error) => {
          return reject(error)
        },
      )
    })
  }
}

export const fetchLatestUserFavoriteProviders = ({ latestSearchId }: { latestSearchId: string }) => {
  const request = getLatestUserFavoriteProviders(latestSearchId)

  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (latestUserProviders) => {
          // reset when we land back on homebase
          dispatch({
            type: SET_LATEST_MATCHES_SEARCH,
            value: null,
          })
          dispatch({
            type: GET_LATEST_USER_FAVORITE_PROVIDERS,
            payload: latestUserProviders.data,
          })
          resolve(latestUserProviders.data)
        },
        (error) => {
          reject(error)
        },
      )
    })
  }
}

export const setLatestMatchesSearch = (value?: null | TriageSearch) => {
  return {
    type: SET_LATEST_MATCHES_SEARCH,
    value,
  }
}

export const setLoadingLatestProviderMatches = (value: boolean) => {
  return {
    type: SET_LOADING_LATEST_PROVIDER_MATCHES,
    value,
  }
}

export const postLatestSearchProviderResults = (data: PostLatestProviderResults, navigate: NavigateFunction) => {
  return async (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const $$preferredAppointmentTypes = getUserPreferredAppointmentTypes(getState())
    const latestUserProviders = getLatestUserFavoriteProvidersSelector(getState())
    const treatmentOption = latestUserProviders[latestUserProviders.length - 1]?.treatmentPreference

    dispatch(setLoadingLatestProviderMatches(true))

    try {
      const endpoint = `/v1/onboarding/search/${data.searchId}/latest/provider-results`
      const response = await axiosInstance.post<SearchProviders>(endpoint, {
        timeZone: data.timeZone,
        blendedCareProgramId: data.blendedCareProgramId,
      })
      dispatch(handleProviderRecommendationsResults(response.data, $$preferredAppointmentTypes, treatmentOption))
      const providersInfo = response.data.providerResults.providersInfo
      if (providersInfo.length === 1) {
        dispatch(
          setProvider({
            provider: providersInfo[0],
            isOnlyProviderReturned: true,
          }),
        )
        navigate(`/secure/onboard/provider/${providersInfo[0].lyra_id}`)
      }
    } catch (error: any) {
      /**
       * If Arbiter cannot find any providers, go back to home and show error banner
       */
      dispatch(changeRoute(PAGE_ROUTES.HOME, '/secure/index/'))
      dispatch(BannerActions.setBannerAndErrorMessage(error, true))
    } finally {
      dispatch(setLoadingLatestProviderMatches(false))
    }
  }
}

export const setIsCurrentSearchDirectPath = (payload: boolean) => {
  return {
    type: SET_IS_CURRENT_SEARCH_DIRECT_PATH,
    payload,
  }
}

export const setDirectLinkIntent = (payload: string) => {
  return {
    type: SET_DIRECT_LINK_INTENT,
    payload,
  }
}

export const clearDirectLinkIntent = () => {
  return {
    type: CLEAR_DIRECT_LINK_INTENT,
  }
}

export const setDirectLinkSource = (payload: string) => {
  return {
    type: SET_DIRECT_LINK_SOURCE,
    payload,
  }
}

export const clearDirectLinkSource = () => {
  return {
    type: CLEAR_DIRECT_LINK_SOURCE,
  }
}

export const postQueueHighAlert = ({ phoneNumber, searchId }: { phoneNumber: string; searchId: string }) => {
  const request = {
    searchId,
    phoneNumber,
  }
  return axiosInstance.post(`/v1/patient/queueHighAlertCallback`, request)
}
