import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  Appointment,
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  DirectDedicatedCareNavigatorInfoValues,
  MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT,
  ProviderInfo,
  S4C_EVENTS,
  TRIAGE_ENTRY_POINTS,
  TriageSearch,
  useBackToTriage,
  useFlags,
  useInProgressSearch,
} from '@lyrahealth-inc/shared-app-logic'
import {
  AppContext,
  NavBar,
  OnboardingExitModal,
  ProgressBar,
  ThemeType,
  tID,
  toJS,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { getShouldShowLiveChat } from '../../../data/appGlobals/appGlobalsSelectors'
import {
  getCustomerLaunchDate,
  getCustomerPhone,
  getDefaultDirectCareNavigatorBookingLink,
  getIsCustomerLyra,
  getIsLyraSocialCareEnabled,
} from '../../../data/customer/customerSelectors'
import { getAppointmentReschedule, getSessions } from '../../../data/lyraTherapy/lyraTherapySelectors'
import { convertRouteToMixpanelPage, trackEventWithObj } from '../../../data/mixpanel'
import { getRedirectUrlOnTriageExit, getTriageEntryPoint } from '../../../data/register/registerSelectors'
import {
  getDirectDedicatedCareNavigatorInfo,
  getDirectDedicatedCareNavigatorLink,
} from '../../../data/user/userActions'
import {
  getId,
  getIsUserInternational,
  getUserChildren,
  getUserCountryName,
  getUserDedicatedCareNavigatorInfo,
  getUserDirectDedicatedCareNavigatorLink,
  getUserLegalFirstName,
  getUsername,
  getUserSalesforceId,
} from '../../../data/user/userSelectors'
import { handleZeroViewSelect } from '../../../features/appointmentDashboard/data/appointmentDashboardActions'
import { markDashboardAsStale } from '../../../features/lyraTherapy/data/LyraTherapyActions'
import { setLatestMatchesSearch } from '../../../features/onboard/data/onboardDataActions'
import {
  getIsLatestMatches,
  getLatestMatchesSearch,
  getOnboardProviderProfileData,
} from '../../../features/onboard/data/onboardSelectors'
import {
  clearTriage,
  getWordCloudWords,
  postTriageSearch,
  setSelectedSearchId,
} from '../../../features/triage/data/triageActions'
import {
  getIsCreatingSearch,
  getSearcherInfo,
  getSearches,
  getSelectedSearch,
  getSelectedSearchPrimaryNeed,
  getWordCloud,
} from '../../../features/triage/data/triageSelectors'
import { NEGATIVE_PADDING_PATHS } from '../../constants/routingConstants'
import { useCareNavigatorHeaderEntryPoint } from '../../hooks/useCareNavigatorHeaderEntryPoint'
import { useNavigateToLatestProviderMatches } from '../../hooks/useNavigateToLatestProviderMatches'
import { goToCareNavigatorSchedulingPage } from '../../utils/onboardUtils'
import { matchPaths } from '../../utils/stringUtils'
import ChatLive from '../chatLive/ChatLive'

type OnboardingNavBarProps = ConnectedProps<typeof connector> & {
  transparencyEnabled?: boolean
  hasConnectedCalendar: boolean
  skipExitModalWhenNoProviderConnectedCalendar?: boolean
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  isCreatingSearch: boolean
  showLiveChat: boolean
  percentProgress?: number
  progressAnimationDuration?: number
  onBack?: () => void
  oatmealBackground?: boolean
  padBottom?: boolean
  setLatestMatchesSearch: () => void
  latestMatchSearch?: TriageSearch
  providerProfileData: ProviderInfo
  searches: TriageSearch[]
  providerSessions: { appointments: Appointment[] }
  isLatestMatches: boolean
  showBackButton?: boolean
  negativePadBottom?: boolean
  testId?: string
  userDedicatedCareNavigatorInfo: DirectDedicatedCareNavigatorInfoValues
}

const FixedContainer = styled.View<{
  backgroundColor: string
  isTransparent?: boolean
  showBorder?: boolean
  theme: ThemeType
}>(({ backgroundColor, theme, isTransparent, showBorder }) => ({
  position: 'sticky',
  top: '0px',
  left: '0px',
  right: '0px',
  zIndex: 100,
  backgroundColor: isTransparent ? 'transparent' : backgroundColor,
  borderBottomWidth: showBorder ? `1px` : undefined,
  borderBottomColor: showBorder ? theme.colors.borderDefault : undefined,
}))

const ProgressBarContainer = styled(ProgressBar)<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.spacing['16px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
}))

const OnboardingNavBar: React.FC<OnboardingNavBarProps> = ({
  transparencyEnabled = true,
  hasConnectedCalendar,
  skipExitModalWhenNoProviderConnectedCalendar = false,
  isUserInternational,
  customerPhoneNumber,
  userId,
  getDirectDedicatedCareNavigatorLink,
  userDirectDedicatedCareNavigatorLink,
  defaultDirectCareNavigatorBookingLink,
  name,
  email,
  isLyrian,
  salesforceId,
  isCreatingSearch,
  launchDate,
  shouldShowCareNavigatorHeaderEntrypoint = true,
  showLiveChat,
  percentProgress,
  onBack,
  markDashboardAsStale,
  oatmealBackground,
  padBottom,
  clearTriage,
  setLatestMatchesSearch,
  latestMatchSearch,
  providerProfileData,
  trackEventWithObj,
  userChildren,
  searches,
  providerSessions,
  setSelectedSearchId,
  handleZeroViewSelect,
  isLatestMatches,
  showBackButton,
  negativePadBottom,
  testId,
  selectedSearch,
  searcherInfo,
  postTriageSearch,
  wordCloudWords,
  primaryNeed,
  getWordCloudWords,
  triageEntryPoint,
  redirectUrlOnTriageExit,
  progressAnimationDuration = 300,
  userDedicatedCareNavigatorInfo,
  getDirectDedicatedCareNavigatorInfo,
  userCountryName,
  isLyraSocialCareEnabled,
}) => {
  const { showUpdatedConnectedCalendar } = useContext(AppContext)
  const { isLctTeensEnabled } = useFlags()
  const { isMobileSized } = useMediaQuerySize()
  const navigate = useNavigate()
  const [hasScrolled, setHasScrolled] = useState(true)
  const [containerHeight, setContainerHeight] = useState(96)

  const navigateBackToTriage = useBackToTriage({
    goBack: () => {
      navigate(-1)
    },
    search: selectedSearch as TriageSearch,
    country: userCountryName,
    lyraSocialCareEnabled: isLyraSocialCareEnabled,
    searcherInfo,
    isCreatingSearch,
    postTriageSearch,
    getWordCloudWords,
    wordCloudWords,
    primaryNeedsWord: primaryNeed ?? 'N/A',
    isLctTeensEnabled,
  })

  const setHeaderTransparency = useCallback(() => {
    if (window.scrollY === 0) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', setHeaderTransparency)
    return () => {
      window.removeEventListener('scroll', setHeaderTransparency)
    }
  }, [setHeaderTransparency])

  const [isModalVisible, setIsModalVisible] = useState(false)

  // Initialize CareNavigatorHeaderEntryPoint component variables
  const {
    isShowingBottomSheet,
    setIsShowingBottomSheet,
    snapPoints,
    bottomSheetRef,
    openBottomSheet,
    closeBottomSheet,
    careNavigatorConsolidatedBottomSheet,
    startChat,
  } = useCareNavigatorHeaderEntryPoint({
    isUserInternational,
    isMobileSized,
    trackEventWithObj,
    customerPhoneNumber,
    userId,
    name,
    email,
    salesforceId,
    launchDate,
    isLyrian,
    // @ts-ignore
    getDirectDedicatedCareNavigatorLink,
    userDirectDedicatedCareNavigatorLink,
    defaultDirectCareNavigatorBookingLink,
    goToCareNavigatorSchedulingPage,
    showLiveChat,
    userDedicatedCareNavigatorInfo,
  })

  const careNavigatorHeaderEntryPointModalProps = {
    isShowingBottomSheet,
    setIsShowingBottomSheet,
    snapPoints,
    bottomSheetRef,
    openBottomSheet,
    closeBottomSheet,
  }
  const { pathname, state } = useLocation()
  const fromPathName = state?.from
  const inProgressSearch = useInProgressSearch(searches, providerSessions.appointments)
  const previouslySelectedAppointment = useSelector(getAppointmentReschedule)
  const displayUpdatedConnectedCalendar = showUpdatedConnectedCalendar && !previouslySelectedAppointment

  const navigateToLatestProviderMatches = useNavigateToLatestProviderMatches({
    setLatestMatchesSearch,
    setSelectedSearchId,
    inProgressSearch,
    handleZeroViewSelect,
  })

  // Hide the care navigator entrypoint for care navigator pages
  shouldShowCareNavigatorHeaderEntrypoint = !matchPaths(pathname, [
    '/secure/onboard/care-advocate-consultation',
    '/secure/onboard/care-advocate-consultation/confirmation',
    '/secure/onboard/care-navigator-options',
    '/secure/onboard/contact-care-team',
    '/secure/onboard/contact-care-team/confirmation',
  ])

  // No exit modal for these pages.
  skipExitModalWhenNoProviderConnectedCalendar = matchPaths(pathname, [
    '/secure/onboard/provider/:id',
    '/secure/onboard/provider/:id/request',
    '/secure/wellness/toolkit/:id',
    'secure/onboard/contact-care-team/confirmation',
  ])

  // Oatmeal color pages
  oatmealBackground =
    oatmealBackground ||
    matchPaths(pathname, [
      '/secure/onboard/match-location',
      '/secure/onboard/member-preferences',
      '/secure/onboard/member-preferences/selection',
      '/secure/onboard/meds-options-explained',
      '/secure/onboard/more-options',
      '/secure/onboard/no-renew-providers',
      '/secure/onboard/health-plan-eligibility/pending',
      '/secure/onboard/care-navigator-options',
      '/secure/onboard/child-care-navigator-options',
      '/secure/onboard/care-advocate-consultation',
      '/secure/onboard/care-advocate-consultation/confirmation',
      '/secure/onboard/contact-care-team/confirmation',
      '/secure/onboard/about-child',
      '/secure/onboard/about-child/info',
      '/secure/onboard/custom-child-care',
      '/secure/onboard/provider/:id/appointment',
      '/secure/onboard/provider/:id/request',
      ...(displayUpdatedConnectedCalendar ? ['/secure/onboard/provider/:id/schedule'] : []),
    ])

  // Need to add extra negative padding so that nav sits on top of image header
  negativePadBottom = matchPaths(pathname, NEGATIVE_PADDING_PATHS.concat('/secure/onboard/treatment-options'))

  transparencyEnabled = !matchPaths(pathname, [
    '/secure/onboard/member-preferences',
    '/secure/onboard/member-preferences/selection',
    '/secure/onboard/about-child',
    '/secure/onboard/about-child/info',
    '/secure/onboard/custom-child-care',
    '/secure/onboard/provider/:id/appointment',
    '/secure/onboard/provider/:id/request',
  ])

  const hideBorder = matchPaths(pathname, [
    ...(displayUpdatedConnectedCalendar ? ['/secure/onboard/provider/:id/schedule'] : []),
  ])

  const resetTriageToHomebase = () => {
    clearTriage()
    markDashboardAsStale()
    setLatestMatchesSearch(null)
    if (triageEntryPoint === TRIAGE_ENTRY_POINTS.ESSENTIALS) {
      navigate(redirectUrlOnTriageExit)
    } else {
      navigate('/secure/index/search-care')
    }
  }

  const { colors } = useTheme()
  const backgroundColor = oatmealBackground ? colors.backgroundSecondary : colors.backgroundPrimary
  return (
    <>
      <FixedContainer
        testID={tID('OnboardingNavBar')}
        backgroundColor={backgroundColor}
        isTransparent={transparencyEnabled && hasScrolled}
        showBorder={hideBorder ? false : !hasScrolled}
        onLayout={(e) => {
          setContainerHeight(e.nativeEvent.layout.height)
        }}
      >
        <NavBar
          testId={testId}
          showBackButton={showBackButton}
          onBack={() => {
            if (latestMatchSearch && isEmpty(providerProfileData)) {
              resetTriageToHomebase()
            } else if (
              fromPathName &&
              matchPaths(fromPathName, [
                '/secure/onboard/t1-multi-providers/latest-provider-matches',
                '/secure/onboard/coaches/latest-provider-matches',
              ])
            ) {
              navigateToLatestProviderMatches()
            } else if (pathname && matchPaths(pathname, ['/secure/onboard/child-care-navigator-options'])) {
              navigateBackToTriage()
            } else {
              onBack ? onBack() : navigate(-1)
            }
          }}
          onExit={() => {
            if (skipExitModalWhenNoProviderConnectedCalendar && !hasConnectedCalendar) {
              navigate('/secure/index/search-care')
            } else {
              setIsModalVisible(true)
            }
          }}
          isTransparent
          careNavigatorHeaderEntryPointModalProps={careNavigatorHeaderEntryPointModalProps}
          careNavigatorConsolidatedBottomSheet={careNavigatorConsolidatedBottomSheet}
          isSidePanel={!isMobileSized}
          shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
          onCareNavigatorHeaderEntryPointButtonPress={async () => {
            getDirectDedicatedCareNavigatorInfo(userId)
            trackEventWithObj({
              event: S4C_EVENTS.CLICK_TO_CONTACT_CN,
              [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
              [COMMON_MIXPANEL_PROPERTIES.PAGE]: 'global nav care navigator',
              [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: convertRouteToMixpanelPage(pathname, userChildren?.length > 0),
              [COMMON_MIXPANEL_PROPERTIES.BUTTON_TEXT]: MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT(isUserInternational),
              [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational ? 'International' : 'Domestic',
            })
          }}
        />
        {percentProgress && (
          <ProgressBarContainer
            progress={percentProgress}
            backgroundColor={[colors.progressIndicatorBackgroundTop, colors.progressIndicatorBackgroundBottom]}
            color={colors.progressIndicator}
            width={-1}
            height={4}
            borderRadius={4}
            duration={progressAnimationDuration}
          />
        )}
        <OnboardingExitModal
          isVisible={isModalVisible}
          onExit={() => {
            resetTriageToHomebase()
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
          isLatestMatches={isLatestMatches}
        />
        <ChatLive hideButton startChat={startChat} />
      </FixedContainer>
      {padBottom && <View style={{ marginBottom: containerHeight }} />}
      {negativePadBottom && <View style={{ marginBottom: -containerHeight }} />}
    </>
  )
}

const mapStateToProps = ($$state: any) => ({
  // If re-enabling this nav bar, we don't depend on connected calendar anymore so should reconsider logic here
  hasConnectedCalendar: false,
  isUserInternational: getIsUserInternational($$state),
  customerPhoneNumber: getCustomerPhone($$state),
  userDirectDedicatedCareNavigatorLink: getUserDirectDedicatedCareNavigatorLink($$state),
  defaultDirectCareNavigatorBookingLink: getDefaultDirectCareNavigatorBookingLink($$state),
  name: getUserLegalFirstName($$state),
  email: getUsername($$state),
  isLyrian: getIsCustomerLyra($$state),
  salesforceId: getUserSalesforceId($$state),
  launchDate: getCustomerLaunchDate($$state),
  userId: getId($$state),
  showLiveChat: getShouldShowLiveChat($$state),
  latestMatchSearch: getLatestMatchesSearch($$state),
  providerProfileData: getOnboardProviderProfileData($$state),
  userChildren: getUserChildren($$state)?.toJS(),
  selectedSearch: getSelectedSearch($$state),
  searcherInfo: getSearcherInfo($$state),
  providerSessions: getSessions($$state),
  searches: getSearches($$state),
  isLatestMatches: getIsLatestMatches($$state),
  wordCloudWords: getWordCloud($$state),
  primaryNeed: getSelectedSearchPrimaryNeed($$state),
  isCreatingSearch: getIsCreatingSearch($$state),
  triageEntryPoint: getTriageEntryPoint($$state),
  redirectUrlOnTriageExit: getRedirectUrlOnTriageExit($$state),
  userDedicatedCareNavigatorInfo: getUserDedicatedCareNavigatorInfo($$state),
  isLyraSocialCareEnabled: getIsLyraSocialCareEnabled($$state),
  userCountryName: getUserCountryName($$state),
})

const mapDispatchToProps = {
  trackEventWithObj,
  getDirectDedicatedCareNavigatorLink,
  markDashboardAsStale,
  clearTriage,
  setLatestMatchesSearch,
  setSelectedSearchId,
  handleZeroViewSelect,
  postTriageSearch,
  getWordCloudWords,
  getDirectDedicatedCareNavigatorInfo,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(OnboardingNavBar))
