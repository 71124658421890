import { defineMessage } from 'react-intl'

import { isEqual } from 'lodash-es'

import { PreferenceMetadataConfig, RENDER_CONDITION_TARGETS } from './types'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const lgbtqiaPreference: PreferenceMetadataConfig = {
  name: 'isLgbtqia',
  title: MEMBER_PREFERENCE_LABELS['LGBTQIA+'],
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS['LGBTQIA+'],
      id: MEMBER_PREFERENCE_IDS['LGBTQIA+'],
    },
  ],
  icon: 'MemberPreferenceLGBTQIAIllustrationIcon',
  promptText: defineMessage({
    defaultMessage: 'I prefer a provider who identifies as:',
    description: 'Text prompting the user to select options they associate with',
  }),
  footerText: {
    message: defineMessage({
      defaultMessage:
        'We’ll prioritize finding you providers who are LGBTQIA+. We’ll also show you providers with experience supporting the LGBTQIA+ community.',
      description:
        'Description text clarifying to users that selecting an option here will show experienced providers with the LGBTQIA+ community',
    }),
    renderConditions: [
      {
        target: RENDER_CONDITION_TARGETS.SELECTED_PREFERENCES,
        path: 'isLgbtqia',
        fn: (val) => isEqual(val, [MEMBER_PREFERENCE_IDS['LGBTQIA+']]),
      },
    ],
    color: 'textInactive',
  },
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.COACHING,
    S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS,
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
  ],
}

export default lgbtqiaPreference
