import { useMemo, useState } from 'react'

import { noop } from 'lodash-es'

import { MinorSessionUsage } from '../../../models/healthPlan/HealthPlan'
import {
  GetAllTriageSearchesActions,
  NewSearchData,
  SearcherInfo,
  TriageSearch,
} from '../../../models/searchForCare/SearchForCare'
import { User } from '../../../models/user/User'
import { S4C_EVENTS, S4C_PROPERTIES } from '../../common/constants/mixpanelConstants'
import { getUserAge } from '../../common/utils/userUtils'
import { getIsMinorEAPSessionExhausted } from '../../healthPlan/utils'
import { getNewSearchData } from '../utils'

interface NavigationRoutes {
  triage: () => void
  childInformation: () => void
  back: () => void
  exit: () => void
  careNavigator: () => void
  otherChildCareInfo: (dob: string) => (() => void) | null
  healthPlanInfo: () => void
  triageSummary: () => void
}

export interface useChildSelectionArgs {
  navigationRoutes: NavigationRoutes
  user: User
  userChildren?: User[]
  customerName: string
  setSearcherInfo: (firstName?: string, lastname?: string, dob?: string, childLyraId?: string) => SearcherInfo | null
  trackEvent?: (event: string, props?: Record<string, unknown>) => void
  eventProps?: Dict
  postTriageSearch: (arg: NewSearchData) => Promise<any>
  setSelectedSearch: (searchId: string) => void
  logException: (message: string, extras: any, error: unknown) => void
  getAllTriageSearches: (arg: GetAllTriageSearchesActions) => Promise<any>
  fetchChildEpisodes?: (params: { userLyraId: string; childLyraId: string }) => Promise<any>
  isLctTeensEnabled: boolean
  isHealthPlanConfirmed: boolean
  hasHPIandEAPPrograms: boolean
  minorsSessionUsages: MinorSessionUsage[]
  numberOfSessions?: string
  shouldShowTriageSummary?: boolean
}

/**
 * Returns all the props needed to render the treatment recommendation page
 */

export const useChildSelection = ({
  navigationRoutes,
  user,
  userChildren = [],
  customerName,
  setSearcherInfo,
  trackEvent = noop,
  eventProps,
  postTriageSearch,
  setSelectedSearch,
  logException,
  getAllTriageSearches,
  fetchChildEpisodes,
  isLctTeensEnabled,
  isHealthPlanConfirmed,
  hasHPIandEAPPrograms,
  minorsSessionUsages,
  numberOfSessions,
  shouldShowTriageSummary,
}: useChildSelectionArgs) => {
  const [selectedChild, setSelectedChild] = useState<User>()

  const childList = useMemo(
    () =>
      [...userChildren]
        .sort((a: User, b: User) => a.firstname.localeCompare(b.firstname))
        .map((c: User) => {
          return {
            firstname: c.firstname,
            lyraId: c.lyraId,
            dob: c.dob,
            isRegistered: c.emailVerified,
          }
        }),
    [userChildren],
  )

  const onSelectChild = (lyraId: string) => {
    const selected = userChildren.find((c: User) => c.lyraId === lyraId)
    if (selected) {
      setSelectedChild(selected)
    }
  }

  const onContinue = async () => {
    if (!selectedChild) {
      return
    }

    const isEAPSessionsExhausted = getIsMinorEAPSessionExhausted(
      minorsSessionUsages,
      selectedChild.lyraId,
      numberOfSessions,
    )

    const gender = selectedChild.gender?.gender
    const searcherInfo = setSearcherInfo(
      selectedChild.firstname,
      selectedChild.lastname,
      selectedChild.dob,
      selectedChild.lyraId,
    )
    const newSearchData = getNewSearchData({
      dob: selectedChild.dob,
      userId: user.id,
      gender,
      employer: customerName,
      type: searcherInfo?.type,
      childUserId: selectedChild.lyraId,
      isLctTeensEnabled,
    })
    if (eventProps) {
      eventProps[S4C_PROPERTIES.CHILD_LYRA_ID] = selectedChild.lyraId
      eventProps[S4C_PROPERTIES.GUARDIAN_AGE] = getUserAge(user)
    }
    trackEvent(S4C_EVENTS.CLICK_CONTINUE_AFTER_SELECTING_CHILD, eventProps)
    const otherChildCareInfoRoute = navigationRoutes.otherChildCareInfo(selectedChild.dob ?? '')
    if (otherChildCareInfoRoute) {
      return otherChildCareInfoRoute()
    }
    try {
      const searchResponse = await postTriageSearch(newSearchData)
      const newSearch = searchResponse.payload || (searchResponse as TriageSearch)
      setSelectedSearch(newSearch.id)

      if (selectedChild.lyraId) {
        await getAllTriageSearches({ userId: selectedChild.lyraId, hideError: [404] })
        if (fetchChildEpisodes) {
          // fetch child episodes so we can detect appointment conflicts
          fetchChildEpisodes({ userLyraId: user.lyraId, childLyraId: selectedChild.lyraId })
        }
      }

      if (!isHealthPlanConfirmed && hasHPIandEAPPrograms && isEAPSessionsExhausted) {
        navigationRoutes.healthPlanInfo()
      } else if (shouldShowTriageSummary) {
        navigationRoutes.triageSummary()
      } else {
        navigationRoutes.triage()
      }
    } catch (error) {
      logException('Unable to continue for selected child', { source: 'ChildSelectionScreen.tsx' }, error)
    }
  }

  const goToChildInfoForm = () => {
    trackEvent(S4C_EVENTS.CLICK_ADD_ANOTHER_CHILD, eventProps)
    navigationRoutes.childInformation()
  }

  const handleExitPress = () => {
    navigationRoutes.exit()
  }

  const onPressSupportLink = () => {
    navigationRoutes.careNavigator()
  }

  return {
    onBackPress: navigationRoutes.back,
    onExitPress: handleExitPress,
    loading: false,
    childList,
    onPressSupportLink,
    isContinueButtonLoading: false,
    customerName,
    onSelectChild,
    onAddChild: goToChildInfoForm,
    onContinue,
  }
}
