import { defineMessage } from 'react-intl'

import { PreferenceMetadataConfig } from './types'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const raceEthnicityCoachingPreference: PreferenceMetadataConfig = {
  name: 'ethnicities',
  title: MEMBER_PREFERENCE_LABELS.ethnicities,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.asian,
      id: MEMBER_PREFERENCE_IDS.ASIAN,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.blackOrAfricanAmerican,
      id: MEMBER_PREFERENCE_IDS.BLACK_OR_AFRICAN_AMERICAN,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.hispanicOrLatinx,
      id: MEMBER_PREFERENCE_IDS.HISPANIC_OR_LATINX,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.nativeAmericanOrAlaskaNative,
      id: MEMBER_PREFERENCE_IDS.NATIVE_AMERICAN_OR_ALASKA_NATIVE,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.nativeHawaiianOrPacificIslander,
      id: MEMBER_PREFERENCE_IDS.NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.white,
      id: MEMBER_PREFERENCE_IDS.WHITE,
    },
  ],
  icon: 'MemberPreferencesRaceEthnicityIllustrationIcon',
  promptText: defineMessage({
    defaultMessage: 'I prefer a provider who identifies as any of the following:',
    description: 'Text prompting the user to select options they associate with',
  }),
  visibleFor: [S4C_PROGRAM_OPTION_FLOW.COACHING, S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS],
}

export default raceEthnicityCoachingPreference
