import { useCallback } from 'react'

import UAParser from 'ua-parser-js'

type InAppMessagingParams = {
  lyraId: string
  userFirstName: string
  userLastName: string
  userEmail: string
  customerName: string
  events: { onOnline: () => void; onOffline: () => void; onDisabled: () => void }
}

declare global {
  interface Window {
    hasLoadedInAppMessaging: boolean
  }
}

export const useInAppMessaging = ({
  lyraId,
  userFirstName,
  userLastName,
  userEmail,
  customerName,
  events,
}: InAppMessagingParams) => {
  const setInAppMessagingProps = useCallback(() => {
    const userAgent = new UAParser(window.navigator.userAgent)
    const userBrowser = userAgent.getResult().browser.name
    if (window.hasLoadedInAppMessaging) {
      const hiddenPrechatFields = {
        First_Name: userFirstName || '',
        Last_Name: userLastName || '',
        Email: userEmail || '',
        Lyra_ID: lyraId || '',
        Microsite: customerName || '',
        Browser: userBrowser,
      }

      ;(window as $TSFixMe).embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(hiddenPrechatFields)
    }
  }, [customerName, lyraId, userEmail, userFirstName, userLastName])

  const checkBusinessHours = () => {
    const businessHoursTimeZone = 'America/Los_Angeles'
    const businessHours = [
      { day: 'Monday', from: '06:00', to: '20:00' },
      { day: 'Tuesday', from: '06:00', to: '20:00' },
      { day: 'Wednesday', from: '06:00', to: '20:00' },
      { day: 'Thursday', from: '06:00', to: '20:00' },
      { day: 'Friday', from: '06:00', to: '18:00' },
    ]
    const now = new Date()
    const currentDayInWeek = now.toLocaleString('en-US', {
      timeZone: businessHoursTimeZone,
      weekday: 'long',
    })
    const currentTime = new Intl.DateTimeFormat([], {
      timeZone: businessHoursTimeZone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    }).format(now)
    const workingTimes = businessHours.filter((times) => times.day === currentDayInWeek)

    return workingTimes.some((time) => {
      if (currentTime >= time.from && currentTime <= time.to) return true
      return false
    })
  }

  const launchInAppMessaging = () => {
    ;(window as $TSFixMe).embeddedservice_bootstrap.utilAPI.launchChat()
  }

  const loadInAppMessaging = useCallback(() => {
    const { onOnline, onOffline, onDisabled } = events

    if (window.hasLoadedInAppMessaging) {
      const online = checkBusinessHours()
      if (online && !window.Cypress) {
        onOnline()
        setInAppMessagingProps()
        launchInAppMessaging()
      } else {
        onOffline()
      }
    } else {
      onDisabled()
    }
  }, [events, setInAppMessagingProps])

  return { loadInAppMessaging }
}
