import { useCallback } from 'react'

import { noop } from 'lodash-es'

import {
  PatchOrPutTriageSearchActions,
  TreatmentRecommendations,
  TriageSearch,
} from '../../../models/searchForCare/SearchForCare'
import { MEETING_FORMATS } from '../../../models/session/Session'
import { YearProgramCoverage } from '../../../models/user/User'
import { LD_CUSTOM_EVENTS } from '../../common/constants/constants'
import { MIXPANEL_PROPERTY, S4C_EVENTS, S4C_PROPERTIES } from '../../common/constants/mixpanelConstants'
import {
  convertTreatmentOptionValueForBackend,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { isTreatmentOptionHPIExclusive } from '../../healthPlan/utils'

export interface NavigationRoutes {
  locationFinder: () => void
  healthPlanInfo: () => void
}

export interface UseSelectTreatmentOptionArgs {
  navigationRoutes: NavigationRoutes
  selectedSearch?: TriageSearch
  treatmentRecs: TreatmentRecommendations | null
  currentProgramCoverageBreakdown: YearProgramCoverage
  setMeetingFormat: (format: MEETING_FORMATS) => void
  patchTriageSearch: (args: PatchOrPutTriageSearchActions) => any
  setSelectedTreatmentOption: (args: TREATMENT_OPTIONS_TYPE) => void
  trackEvent: (event: string, eventProps?: { [key in MIXPANEL_PROPERTY]?: any }) => void
  launchDarklyTrack?: (event: LD_CUSTOM_EVENTS) => void
  isHealthPlanConfirmed: boolean
}

export const useSelectTreatmentOption = ({
  navigationRoutes,
  selectedSearch,
  treatmentRecs,
  currentProgramCoverageBreakdown,
  setMeetingFormat,
  patchTriageSearch,
  setSelectedTreatmentOption,
  trackEvent,
  isHealthPlanConfirmed,
  launchDarklyTrack = noop,
}: UseSelectTreatmentOptionArgs) => {
  const navigateAfterTreatmentOptionPress = useCallback(
    async (submittedTreatmentOption: TREATMENT_OPTIONS_TYPE, shouldCheckHPI?: boolean) => {
      if (shouldCheckHPI) {
        const treatmentOptionHPIExclusive = isTreatmentOptionHPIExclusive({
          treatmentOptionName: submittedTreatmentOption,
          customerPrograms: currentProgramCoverageBreakdown,
        })
        if (treatmentOptionHPIExclusive && !isHealthPlanConfirmed) {
          return navigationRoutes.healthPlanInfo()
        }
      }

      switch (submittedTreatmentOption) {
        case TREATMENT_OPTIONS.COUPLES_THERAPY:
        case TREATMENT_OPTIONS.FAMILY_THERAPY:
        case TREATMENT_OPTIONS.INDIVIDUAL_THERAPY:
          launchDarklyTrack(LD_CUSTOM_EVENTS.INDIVIDUAL_THERAPY_REC_CLICKED)
          navigationRoutes.locationFinder()
          break
        case TREATMENT_OPTIONS.COACHING:
          if (treatmentRecs?.eligibleTreatmentOptions.includes(TREATMENT_OPTIONS.LIVE_MESSAGING_COACHING)) {
            // Users eligible for live messaging coaching are prompted to select their preferred coaching appointment modality.
            navigationRoutes.locationFinder()
          } else {
            setMeetingFormat(MEETING_FORMATS.VIDEO)
            navigationRoutes.locationFinder()
          }
          break
        default:
          navigationRoutes.locationFinder()
      }
    },
    [
      currentProgramCoverageBreakdown,
      isHealthPlanConfirmed,
      navigationRoutes,
      launchDarklyTrack,
      treatmentRecs?.eligibleTreatmentOptions,
      setMeetingFormat,
    ],
  )

  const onSelectTreatmentOption = useCallback(
    ({
      treatmentOption,
      shouldCheckHPI = true,
      ctaEntrypoint = '',
    }: {
      treatmentOption: TREATMENT_OPTIONS_TYPE
      shouldCheckHPI?: boolean
      ctaEntrypoint?: string
    }) => {
      if (selectedSearch?.id) {
        patchTriageSearch({
          id: selectedSearch.id,
          data: {
            selected_treatment_option: convertTreatmentOptionValueForBackend(treatmentOption),
          },
        })
      }
      setSelectedTreatmentOption(treatmentOption)
      if (shouldCheckHPI) {
        // Do not track event from HPI screen.
        trackEvent(S4C_EVENTS.SELECT_TREATMENT_OPTION, {
          [S4C_PROPERTIES.TREATMENT_OPTION]: convertTreatmentOptionValueForBackend(treatmentOption),
          [S4C_PROPERTIES.IS_PRIMARY_RECOMMENDATION]: (treatmentRecs?.primaryTreatmentOptions ?? []).includes(
            treatmentOption,
          ),
          [S4C_PROPERTIES.CTA_ENTRYPOINT]: ctaEntrypoint,
        })
      }
      navigateAfterTreatmentOptionPress(treatmentOption, shouldCheckHPI)
    },
    [
      navigateAfterTreatmentOptionPress,
      patchTriageSearch,
      selectedSearch?.id,
      setSelectedTreatmentOption,
      trackEvent,
      treatmentRecs?.primaryTreatmentOptions,
    ],
  )

  return onSelectTreatmentOption
}
