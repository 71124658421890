import { useState } from 'react'
import { IntlShape } from 'react-intl'

import { CountryCode } from 'libphonenumber-js'

import {
  extendMetadata,
  FieldSchema,
  FormMetadata,
  PasswordPolicy,
  ThemeColors,
  USER_MAX_AGE_REQUIREMENT,
} from '@lyrahealth-inc/shared-app-logic'

import { AgeCustomError } from './SetUpAccountForm'
import { IS_WEB } from '../../constants'
import { commonInputFieldStyling, pressableFieldStyling } from '../formBody/sharedMetadata'

export type BasicInfoFormValues = {
  countryIsoCode?: CountryCode
  userFirstName?: string
  userLastName?: string
  userDOB?: string
  password?: string
}

export enum BasicInfoField {
  HEADER = 'header',
  COUNTRY_ISO_CODE = 'countryIsoCode',
  ERROR_BANNER = 'errorBanner',
  SUCCESS_BANNER = 'successBanner',
  USER_FULL_NAME = 'userFullName',
  USER_FIRST_NAME = 'userFirstName',
  USER_LAST_NAME = 'userLastName',
  ADD_PREFERRED_NAME = 'addPreferredName',
  USER_PREFERRED_FULL_NAME = 'userPreferredFullName',
  USER_PREFERRED_FIRST_NAME = 'userPreferredFirstName',
  USER_PREFERRED_LAST_NAME = 'userPreferredLastName',
  USER_DATE_OF_BIRTH = 'userDOB',
  USER_ELIGIBILITY_TYPE = 'eligibilityType',
  USER_AGE_BELOW_MIN_ERROR = 'userAgeBelowMinError',
  PASSWORD = 'password',
  USER_EMAIL_ADDRESS = 'userEmailAddress',
}

export type NameFields = {
  firstName: {
    name: string
    isRequired: boolean
  }
  lastName: {
    name: string
    isRequired: boolean
  }
}

export const buildNameFields = (
  intl: IntlShape,
  showInternationalFormat: boolean,
  nameFields: NameFields,
  showEligibilityErrorAsWarning: boolean,
  isPreferredName = false,
): FieldSchema[] => {
  const items: FieldSchema[] = [
    {
      name: nameFields.firstName.name,
      title: intl.formatMessage(
        {
          defaultMessage: `{isPreferredName, select,
            true {{showInternationalFormat, select,
              true {Preferred given name (optional)}
              other {Preferred first name (optional)}
            }}
            other {{showInternationalFormat, select,
              true {Given name}
              other {First name}
            }}
          }`,
          description: '"First name" or "Given name" based on the market',
        },
        { showInternationalFormat, isPreferredName },
      ),
      type: 'string',
      validation: {
        isRequired: nameFields.firstName.isRequired,
        isValidFirstName: { showInternationalFormat },
      },
      widgetProps: {
        showErrorAsWarning: showEligibilityErrorAsWarning,
      },
    },
    {
      name: nameFields.lastName.name,
      title: intl.formatMessage(
        {
          defaultMessage: `{isPreferredName, select,
            true {{showInternationalFormat, select,
              true {Preferred surname (optional)}
              other {Preferred last name (optional)}
            }}
            other {{showInternationalFormat, select,
              true {Surname / family name}
              other {Last name}
            }}
          }`,
          description: '"Surname / family name" or "Last name"',
        },
        { showInternationalFormat, isPreferredName },
      ),
      type: 'string',
      validation: {
        isRequired: nameFields.lastName.isRequired,
        isValidLastName: { showInternationalFormat },
      },
      widgetProps: {
        showErrorAsWarning: showEligibilityErrorAsWarning,
      },
    },
  ]

  // the order of the name fields should be flipped when user is international (last name, first name)
  // vs domestic (first name, last name)
  return showInternationalFormat ? items.reverse() : items
}

const useBasicInfoFields = (
  showInternationalFormat: boolean,
  showBasicInfoErrorBanner: boolean,
  userMinAgeRequirement: number,
  intl: IntlShape,
  shouldDisplayPasswordField: boolean,
  showUserAgeBelowMinWarningError: boolean,
  hasFormSubmitted: boolean,
  colors: ThemeColors,
  passwordPolicy?: PasswordPolicy,
  isMobileSized?: boolean,
  hidePasswordChecklistOnBlur?: boolean,
  hideNameField?: boolean,
  hideDobField?: boolean,
  showEligibilityErrorAsWarning?: boolean,
  isPreferredNameEnabled?: boolean,
  onPressAddPreferredName?: () => void,
  showEditEmailAddressField?: boolean,
): FormMetadata => {
  const [isPreferredNameOpen, setIsPreferredNameOpen] = useState(false)

  const showNameField = !hideNameField
  const showDobField = !hideDobField
  const getNameFields = (): FieldSchema[] => {
    return buildNameFields(
      intl,
      showInternationalFormat,
      {
        firstName: { name: BasicInfoField.USER_FIRST_NAME, isRequired: showNameField },
        lastName: { name: BasicInfoField.USER_LAST_NAME, isRequired: showNameField },
      },
      showEligibilityErrorAsWarning ?? false,
    )
  }
  const getPreferredNameFields = (): FieldSchema[] => {
    return buildNameFields(
      intl,
      showInternationalFormat,
      {
        firstName: { name: BasicInfoField.USER_PREFERRED_FIRST_NAME, isRequired: false },
        lastName: { name: BasicInfoField.USER_PREFERRED_LAST_NAME, isRequired: false },
      },
      false,
      isPreferredNameEnabled,
    )
  }

  return {
    schema: {
      type: 'object',
      properties: {
        [BasicInfoField.USER_EMAIL_ADDRESS]: {
          name: BasicInfoField.USER_EMAIL_ADDRESS,
          type: 'string',
          show: showEditEmailAddressField ?? false,
          title: intl.formatMessage({
            defaultMessage: 'Your email',
            description: "Input field label for editing user's email address",
          }),
          validation: {
            isRequired: true,
            isValidEmail: true,
          },
        },
        [BasicInfoField.ERROR_BANNER]: {
          name: BasicInfoField.ERROR_BANNER,
          type: 'string',
          show: showBasicInfoErrorBanner,
        },
        [BasicInfoField.SUCCESS_BANNER]: {
          name: BasicInfoField.SUCCESS_BANNER,
          type: 'string',
        },
        [BasicInfoField.USER_FULL_NAME]: {
          type: 'array',
          inline: true,
          spaceBetweenFields: 16,
          items: getNameFields(),
          show: showNameField,
        },
        [BasicInfoField.ADD_PREFERRED_NAME]: {
          name: BasicInfoField.ADD_PREFERRED_NAME,
          type: 'string',
          show: isPreferredNameEnabled,
        },
        [BasicInfoField.USER_PREFERRED_FULL_NAME]: {
          type: 'array',
          inline: true,
          spaceBetweenFields: 16,
          items: getPreferredNameFields(),
          show: isPreferredNameOpen,
        },
        [BasicInfoField.USER_DATE_OF_BIRTH]: {
          name: BasicInfoField.USER_DATE_OF_BIRTH,
          title: intl.formatMessage({
            defaultMessage: 'Date of birth',
            description: 'Date of birth field label',
          }),
          placeholder: '',
          type: 'string',
          validation: {
            isRequired: true,
            isValidAgeCustomErrors: {
              ageConstraints: {
                min: userMinAgeRequirement,
                max: USER_MAX_AGE_REQUIREMENT.ADULT,
              },
              customErrorMessages: { belowMin: AgeCustomError.BELOW_MIN },
            },
          },
          widgetProps: {
            showErrorAsWarning: showEligibilityErrorAsWarning,
          },
          show: showDobField,
        },
        [BasicInfoField.USER_AGE_BELOW_MIN_ERROR]: {
          name: BasicInfoField.USER_AGE_BELOW_MIN_ERROR,
          type: 'string',
          show: showUserAgeBelowMinWarningError,
        },
        [BasicInfoField.PASSWORD]: {
          name: BasicInfoField.PASSWORD,
          title: intl.formatMessage({
            defaultMessage: 'Password',
            description: 'Input text prompting the user to type their password',
          }),
          type: 'string',
          validation: {
            isRequired: true,
            isValidPassword: {
              passwordPolicy,
            },
          },
          show: shouldDisplayPasswordField,
          hidePasswordChecklistOnBlur,
          passwordAutoComplete: 'new-password',
        },
      },
    },
    uiSchema: {
      'ui:order': [
        BasicInfoField.USER_EMAIL_ADDRESS,
        BasicInfoField.SUCCESS_BANNER,
        BasicInfoField.ERROR_BANNER,
        BasicInfoField.USER_FULL_NAME,
        BasicInfoField.ADD_PREFERRED_NAME,
        BasicInfoField.USER_PREFERRED_FULL_NAME,
        BasicInfoField.USER_DATE_OF_BIRTH,
        BasicInfoField.USER_AGE_BELOW_MIN_ERROR,
        BasicInfoField.PASSWORD,
      ],
      [BasicInfoField.SUCCESS_BANNER]: { 'ui:widget': 'inlineSuccessBanner' },
      [BasicInfoField.ERROR_BANNER]: {
        'ui:widget': showEligibilityErrorAsWarning ? 'inlineWarningBanner' : 'inlineErrorBanner',
      },
      [BasicInfoField.USER_DATE_OF_BIRTH]: {
        'ui:widget': IS_WEB ? 'datePickerSelect' : 'date',
        'ui:options': {
          ...(IS_WEB && { numYears: USER_MAX_AGE_REQUIREMENT.ADULT }),
          baseInputStyle: {
            marginBottom: isMobileSized ? 16 : 24,
          },
          dateFieldDisplay: 'spinner',
          selectFieldStyle: {
            borderRadius: 8,
            ...(showUserAgeBelowMinWarningError && {
              backgroundColor: hasFormSubmitted ? colors.backgroundError : colors.backgroundWarning,
              borderColor: hasFormSubmitted ? colors.borderError : colors.borderWarning,
            }),
          },
          style: { borderRadius: 8 },
        },
      },
      [BasicInfoField.USER_AGE_BELOW_MIN_ERROR]: {
        'ui:options': {
          bannerMessage: intl.formatMessage(
            {
              defaultMessage:
                'Are you creating an account for a child under the age of {minAge}? You’ll need to create your own account first. Once you’re registered, you’ll be able to set up care for your child.',
              description:
                'Warning text infomring the user they must sign themselves up for care before registering a child',
            },
            {
              minAge: userMinAgeRequirement,
            },
          ),
        },
        'ui:widget': hasFormSubmitted ? 'inlineErrorBanner' : 'inlineWarningBanner',
      },
      [BasicInfoField.PASSWORD]: {
        'ui:widget': 'password',
        'ui:options': {
          ...commonInputFieldStyling(isMobileSized),
          ...{ zIndex: 2 },
        },
      },
      [BasicInfoField.USER_FULL_NAME]: {
        items: [
          {
            'ui:options': commonInputFieldStyling(
              isMobileSized,
              isPreferredNameEnabled ? { marginBottom: 8 } : undefined,
            ),
          },
          {
            'ui:options': commonInputFieldStyling(
              isMobileSized,
              isPreferredNameEnabled ? { marginBottom: 8 } : undefined,
            ),
          },
        ],
      },
      [BasicInfoField.ADD_PREFERRED_NAME]: {
        'ui:widget': 'addIconWidget',
        'ui:options': {
          onPress: () => {
            setIsPreferredNameOpen(true)
            onPressAddPreferredName?.()
          },
          text: intl.formatMessage({
            defaultMessage: 'Add preferred name',
            description: 'Button text that displays input fields to enter a preferred first and last name',
          }),
          isOpen: isPreferredNameOpen,
          size: 20,
          style: pressableFieldStyling(
            isMobileSized,
            isPreferredNameOpen && !isMobileSized ? { marginBottom: 8 } : undefined,
          ),
        },
      },
      [BasicInfoField.USER_PREFERRED_FULL_NAME]: {
        items: [
          {
            'ui:options': commonInputFieldStyling(isMobileSized),
          },
          {
            'ui:options': commonInputFieldStyling(isMobileSized),
          },
        ],
      },
      [BasicInfoField.USER_EMAIL_ADDRESS]: {
        'ui:widget': 'editableInputField',
        'ui:options': {
          ...commonInputFieldStyling(isMobileSized),
          clearValueOnEdit: true,
          editingLabel: intl.formatMessage({
            defaultMessage: 'Update to a personal email address',
            description: 'Label for a text field for user to update their current email address to a personal one',
          }),
        },
      },
    },
  }
}

export const useBasicInfoMetadata = ({
  isCustomerInternational,
  showInternationalFormat,
  showBasicInfoErrorBanner,
  userMinAgeRequirement,
  intl,
  shouldDisplayPasswordField,
  showUserAgeBelowMinWarningError,
  hasFormSubmitted,
  colors,
  passwordPolicy,
  isMobileSized,
  hidePasswordChecklistOnBlur,
  hideNameField,
  hideDobField,
  hideCountrySelector,
  showEligibilityErrorAsWarning,
  isPreferredNameEnabled,
  onPressAddPreferredName,
  showEditEmailAddressField,
}: BasicInfoMetadataProps): FormMetadata => {
  const countrySelectorMetadata: FormMetadata = {
    schema: {
      type: 'object',
      properties: {
        [BasicInfoField.COUNTRY_ISO_CODE]: {
          name: BasicInfoField.COUNTRY_ISO_CODE,
          title: intl.formatMessage({
            defaultMessage: 'Country or territory where you currently live',
            description: 'Selector field for the user preference for country or territory',
          }),
          type: 'string',
          validation: {
            isRequired: true,
          },
        },
      },
    },
    uiSchema: {
      'ui:order': [BasicInfoField.COUNTRY_ISO_CODE],
      [BasicInfoField.COUNTRY_ISO_CODE]: {
        'ui:widget': 'countrySelector',
        'ui:options': {
          ...commonInputFieldStyling(isMobileSized),
          dropdownContainerStyle: {
            top: '80px',
          },
        },
      },
    },
  }

  let metadata = useBasicInfoFields(
    showInternationalFormat,
    showBasicInfoErrorBanner,
    userMinAgeRequirement,
    intl,
    shouldDisplayPasswordField,
    showUserAgeBelowMinWarningError,
    hasFormSubmitted,
    colors,
    passwordPolicy,
    isMobileSized,
    hidePasswordChecklistOnBlur,
    hideNameField,
    hideDobField,
    showEligibilityErrorAsWarning,
    isPreferredNameEnabled,
    onPressAddPreferredName,
    showEditEmailAddressField,
  )
  if (isCustomerInternational && !hideCountrySelector) {
    metadata = extendMetadata(countrySelectorMetadata, metadata)
  }
  return metadata
}

export type BasicInfoMetadataProps = {
  isCustomerInternational: boolean
  showInternationalFormat: boolean
  showBasicInfoErrorBanner: boolean
  userMinAgeRequirement: number
  intl: IntlShape
  shouldDisplayPasswordField: boolean
  showUserAgeBelowMinWarningError: boolean
  hasFormSubmitted: boolean
  colors: ThemeColors
  passwordPolicy?: PasswordPolicy
  isMobileSized?: boolean
  hidePasswordChecklistOnBlur?: boolean
  hideNameField?: boolean
  hideDobField?: boolean
  hideCountrySelector?: boolean
  showEligibilityErrorAsWarning?: boolean
  isPreferredNameEnabled?: boolean
  onPressAddPreferredName?: () => void
  showEditEmailAddressField?: boolean
}
