import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ImageSourcePropType, View } from 'react-native'

import styled from 'styled-components/native'

import { CARE_ADVISOR_EMAIL } from '@lyrahealth-inc/shared-app-logic'

import { UnsubscribeEmailDog } from '../../assets/microsite'
import { BodyText, Headline, Image, Link, PrimaryButton } from '../../atoms'
import { CareNavigatorCareOptionsFooter } from '../../molecules/careNavigatorCareOptionsFooter/CareNavigatorCareOptionsFooter'
import { BodyTextSize, HeadlineSize } from '../../styles'
import { HorizontalCenteredView } from '../../templates'
import { openUrl } from '../../utils'
import { tID } from '../../utils/utils'

export interface UnsubscribePageProps {
  type: UnsubscribePageType
  onContactCareNavigatorPress: () => void
  onEditNotificationSettingsPress: () => void
}

const UnsubscribeEmailDogImage = styled(Image)({
  height: '174px',
  width: '225px',
})

const Container = styled(HorizontalCenteredView)({
  marginTop: '48px',
  marginBottom: '24px',
})

const UnsubscribeContainer = styled(View)({
  maxWidth: '624px',
  gap: '32px',
  flexDirection: 'column',
  alignItems: 'center',
  paddingHorizontal: '48px',
})

const EditNotificationSettingsButton = styled(PrimaryButton)({
  alignSelf: 'center',
})

const ContactCareNavigatorFooter = styled(CareNavigatorCareOptionsFooter)({
  marginTop: '48px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
})

export enum UnsubscribePageType {
  promotion = 'promotion',
  outcomes = 'outcomes',
}

export const UnsubscribePage: FunctionComponent<UnsubscribePageProps> = ({
  type,
  onContactCareNavigatorPress,
  onEditNotificationSettingsPress,
}) => {
  const { formatMessage } = useIntl()

  const unsubscribedHeader = formatMessage({
    defaultMessage: 'Your email has been unsubscribed.',
    description: 'Header text for the unsubscribed page',
  })

  const unsubscribedBody = formatMessage({
    defaultMessage:
      'You’ll still receive important communication from us, like session reminders and provider messages.',
    description: 'Body text for the unsubscribed page',
  })

  const outcomesBody = formatMessage(
    {
      defaultMessage:
        'If you would like to receive assessment emails in the future, email <link>care@lyrahealth.com</link>.',
      description: 'Body text for the unsubscribed page',
    },
    {
      link: (careTeamEmail: string) => (
        <Link text={careTeamEmail} onPress={() => openUrl(`mailto:${CARE_ADVISOR_EMAIL}`)} size={BodyTextSize.SMALL} />
      ),
    },
  )

  const bodyText = type === UnsubscribePageType.outcomes ? outcomesBody : unsubscribedBody

  return (
    <Container testID={tID('UnsubscribePromotionalPage')}>
      <UnsubscribeContainer>
        <Headline
          size={HeadlineSize.SMALL}
          text={unsubscribedHeader}
          textAlign='center'
          testID={tID('UnsubscribePromotionalPage-header')}
        />
        <BodyText text={bodyText} textAlign='center' testID={tID('UnsubscribePromotionalPage-body')} />
        <UnsubscribeEmailDogImage source={UnsubscribeEmailDog as ImageSourcePropType} />
        <EditNotificationSettingsButton
          onPress={onEditNotificationSettingsPress}
          text={formatMessage({
            defaultMessage: 'Edit notification settings',
            description: 'Button to take user to notification settings',
          })}
          testID={tID('UnsubscribePromotionalPage-EditNotificationSettingsButton')}
        />
      </UnsubscribeContainer>
      <ContactCareNavigatorFooter
        careNavigatorLinkTestId={tID('UnsubscribePromotionalPage-CareNavigatorLink')!}
        onContactCareNavigatorPress={onContactCareNavigatorPress}
        subheadTextAlign='center'
      />
    </Container>
  )
}
