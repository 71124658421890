import { WellbeingResponse } from './types'

export enum WellnessCheckInDomain {
  MoodAndEmotions = 'moodAndEmotions',
  ResilienceAndCoping = 'resilienceAndCoping',
  RolesAndRelationships = 'rolesAndRelationships',
  PhysicalHealthAndActivity = 'physicalHealthAndActivity',
  FinancesAndWork = 'financesAndWork',
  EnvironmentCuriosityAndSpirituality = 'environmentCuriosityAndSpirituality',
}

export enum WellnessCheckInFacetGroup {
  Mood = 'mood',
  Emotions = 'emotions',
  Resilience = 'resilience',
  Coping = 'coping',
  Relationships = 'relationships',
  PhysicalActivity = 'physicalActivity',
  Sleep = 'sleep',
  Pain = 'pain',
  Finance = 'finance',
  Work = 'work',
  NewExperiences = 'newExperiences',
  Spiritual = 'spiritual',
  Nature = 'nature',
}

export enum WellnessCheckInValency {
  High,
  Low,
}

export enum WellbeingResult {
  High = 'high wellbeing',
  Low = 'low wellbeing',
}

export const WELLNESS_CHECK_IN_FACET_GROUP_TO_DOMAIN: Record<WellnessCheckInFacetGroup, WellnessCheckInDomain> = {
  [WellnessCheckInFacetGroup.Mood]: WellnessCheckInDomain.MoodAndEmotions,
  [WellnessCheckInFacetGroup.Emotions]: WellnessCheckInDomain.MoodAndEmotions,
  [WellnessCheckInFacetGroup.Resilience]: WellnessCheckInDomain.ResilienceAndCoping,
  [WellnessCheckInFacetGroup.Coping]: WellnessCheckInDomain.ResilienceAndCoping,
  [WellnessCheckInFacetGroup.Relationships]: WellnessCheckInDomain.RolesAndRelationships,
  [WellnessCheckInFacetGroup.PhysicalActivity]: WellnessCheckInDomain.PhysicalHealthAndActivity,
  [WellnessCheckInFacetGroup.Sleep]: WellnessCheckInDomain.PhysicalHealthAndActivity,
  [WellnessCheckInFacetGroup.Pain]: WellnessCheckInDomain.PhysicalHealthAndActivity,
  [WellnessCheckInFacetGroup.Finance]: WellnessCheckInDomain.FinancesAndWork,
  [WellnessCheckInFacetGroup.Work]: WellnessCheckInDomain.FinancesAndWork,
  [WellnessCheckInFacetGroup.NewExperiences]: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
  [WellnessCheckInFacetGroup.Spiritual]: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
  [WellnessCheckInFacetGroup.Nature]: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
}

export const INITIAL_WELLBEING_RESPONSE: WellbeingResponse = {
  [WellnessCheckInDomain.MoodAndEmotions]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.ResilienceAndCoping]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.RolesAndRelationships]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.PhysicalHealthAndActivity]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.FinancesAndWork]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality]: {
    score: 0,
    response: [],
  },
}
