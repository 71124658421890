import React, { FunctionComponent, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'

import {
  useWellnessCheckInRulesEngine,
  wellnessCheckInSchema,
  wellnessCheckInUiSchema,
} from '@lyrahealth-inc/shared-app-logic'

import { FixedButtonFormPageTemplate } from '../../templates/fixedButtonFormPageTemplate/FixedButtonFormPageTemplate'
import { tID } from '../../utils/utils'
import { FormBody } from '../formBody/FormBody'

export interface WellnessCheckInFormProps {
  onSubmit: (values: object) => void
}

/**
 * A component that renders the Wellness Check In Form.
 */

export const WellnessCheckInForm: FunctionComponent<WellnessCheckInFormProps> = ({ onSubmit }) => {
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(1)
  const submit = useRef(noop)
  const totalPages = Object.keys(wellnessCheckInSchema.properties!).length
  const { runRulesEngine } = useWellnessCheckInRulesEngine()

  const handleSaveForm = async ({ values }: { values: Dict }) => {
    const formResponse = await runRulesEngine({ values })
    onSubmit(formResponse)
  }

  return (
    <FixedButtonFormPageTemplate
      navBarProps={{}}
      onSubmitPress={() => {
        if (currentPage === totalPages) {
          submit.current()
        }
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1)
        }
      }}
      buttonText={<FormattedMessage defaultMessage='Continue' description='Button to continue to next page' />}
      testID={tID('WellnessCheckIn')}
    >
      <FormBody
        name='WellnessCheckIn'
        schema={wellnessCheckInSchema}
        uiSchema={wellnessCheckInUiSchema}
        intl={intl}
        enableTranslations
        saveForm={handleSaveForm}
        showCurrentPageOnly
        currentPage={currentPage}
        handleSubmitRef={submit}
        totalPages={totalPages}
      />
    </FixedButtonFormPageTemplate>
  )
}
