import { defineMessage, MessageDescriptor } from 'react-intl'

import { WellnessCheckInDomain, WellnessCheckInFacetGroup, WellnessCheckInValency } from './constants'
import { FieldSchema, FieldSchemaDefinition } from '../../models/formMetadata/FormMetadata'

export const wellnessCheckInSchema: Omit<FieldSchema, 'properties'> & {
  properties: {
    [key: string]: FieldSchemaDefinition<MessageDescriptor | string>
  }
} = {
  type: 'object',
  properties: {
    howAreYouFeelingToday: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'To start, how are you feeling today?',
        description: 'Wellness Check In: Initial question for user completing wellness check in form',
      }),
      mobilePage: 1,
      items: [
        {
          name: 'howAreYouFeelingToday',
          minimum: 0,
          maximum: 6,
          type: 'integer',
        },
      ],
    },
    reflectionInstructions: {
      name: 'reflectionInstructions',
      content: defineMessage({
        defaultMessage:
          '### For the next set of questions, reflect on how you feel most days, during the past two weeks',
        description: 'Wellness Check In: Instructions for completing wellness check in form',
      }),
      mobilePage: 2,
    },
    iFeltDownOrDepressed: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Moods and Emotions',
        description: 'Wellness Check In: Header for the Moods and Emotions Domain',
      }),
      mobilePage: 3,
      items: [
        {
          name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
          title: defineMessage({
            defaultMessage: 'I felt down or depressed',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
            },
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iFeltAnxiousOrStressed: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Moods and Emotions',
        description: 'Wellness Check In: Header for the Moods and Emotions Domain',
      }),
      mobilePage: 4,
      items: [
        {
          name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
          title: defineMessage({
            defaultMessage: 'I felt anxious or stressed',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
            },
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iWorriedAboutThingsICouldNotControl: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Moods and Emotions',
        description: 'Wellness Check In: Header for the Moods and Emotions Domain',
      }),
      mobilePage: 5,
      items: [
        {
          name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
          title: defineMessage({
            defaultMessage: 'I worried about things I could not control',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
            },
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iLookedForwardToEachNewDay: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Moods and Emotions',
        description: 'Wellness Check In: Header for the Moods and Emotions Domain',
      }),
      mobilePage: 6,
      items: [
        {
          name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
          title: defineMessage({
            defaultMessage: 'I looked forward to each new day',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
            },
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    iExperiencedMoodSwings: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Moods and Emotions',
        description: 'Wellness Check In: Header for the Moods and Emotions Domain',
      }),
      mobilePage: 7,
      items: [
        {
          name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
          title: defineMessage({
            defaultMessage: 'I experienced mood swings',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.MoodAndEmotions,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
            },
            {
              fact: WellnessCheckInDomain.MoodAndEmotions,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iGotDiscourageWhenThingsDidntGoMyWay: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Resilience And Coping',
        description: 'Wellness Check In: Header for the Resilience and Coping Domain',
      }),
      mobilePage: 8,
      items: [
        {
          name: `${WellnessCheckInDomain.ResilienceAndCoping}.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
          title: defineMessage({
            defaultMessage: "I got discouraged when things didn't go my way",
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.ResilienceAndCoping,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.ResilienceAndCoping,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.ResilienceAndCoping,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
            },
            {
              fact: WellnessCheckInDomain.ResilienceAndCoping,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iFeltLikeIWasOnMyOwnWhenFacingChallenges: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Resilience And Coping',
        description: 'Wellness Check In: Header for the Resilience and Coping Domain',
      }),
      mobilePage: 9,
      items: [
        {
          name: `${WellnessCheckInDomain.ResilienceAndCoping}.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
          title: defineMessage({
            defaultMessage: 'I felt like I was on my own when facing challenges',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.ResilienceAndCoping,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.ResilienceAndCoping,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.ResilienceAndCoping,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
            },
            {
              fact: WellnessCheckInDomain.ResilienceAndCoping,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Resilience And Coping',
        description: 'Wellness Check In: Header for the Resilience and Coping Domain',
      }),
      mobilePage: 10,
      items: [
        {
          name: `${WellnessCheckInDomain.ResilienceAndCoping}.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
          title: defineMessage({
            defaultMessage: "I often avoided a problem because I didn't want to deal with it",
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.ResilienceAndCoping,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.ResilienceAndCoping,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.ResilienceAndCoping,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
            },
            {
              fact: WellnessCheckInDomain.ResilienceAndCoping,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Roles and Relationships',
        description: 'Wellness Check In: Header for the Roles and Relationships Domain',
      }),
      mobilePage: 11,
      items: [
        {
          name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
          title: defineMessage({
            defaultMessage: 'It was hard to make time to be a good family member, employee, and friend',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
            },
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iWasOverwhelmedWithWhatIHadToAccomplishInADay: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Roles and Relationships',
        description: 'Wellness Check In: Header for the Roles and Relationships Domain',
      }),
      mobilePage: 12,
      items: [
        {
          name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
          title: defineMessage({
            defaultMessage: 'I was overwhelmed with what I had to accomplish in a day',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
            },
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    myRelationshipsAtWorkWereSatisfying: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Roles and Relationships',
        description: 'Wellness Check In: Header for the Roles and Relationships Domain',
      }),
      mobilePage: 13,
      items: [
        {
          name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
          title: defineMessage({
            defaultMessage: 'My relationships at work were satisfying',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
            },
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    iFeltFulfilledInMyCloseRelationships: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Roles and Relationships',
        description: 'Wellness Check In: Header for the Roles and Relationships Domain',
      }),
      mobilePage: 14,
      items: [
        {
          name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
          title: defineMessage({
            defaultMessage: 'I felt fulfilled in my close relationships',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
            },
            {
              fact: WellnessCheckInDomain.RolesAndRelationships,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.RolesAndRelationships,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    iDidntGetEnoughPhysicalActivityOrExercise: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Physical Health and Activity',
        description: 'Wellness Check In: Header for the Physical Health and Activity Domain',
      }),
      mobilePage: 15,
      items: [
        {
          name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
          title: defineMessage({
            defaultMessage: "I didn't get enough physical activity or exercise",
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
            },
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iOftenFeltRestedWhenIWokeUp: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Physical Health and Activity',
        description: 'Wellness Check In: Header for the Physical Health and Activity Domain',
      }),
      mobilePage: 16,
      items: [
        {
          name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
          title: defineMessage({
            defaultMessage: 'I often felt rested when I woke up',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
            },
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Physical Health and Activity',
        description: 'Wellness Check In: Header for the Physical Health and Activity Domain',
      }),
      mobilePage: 17,
      items: [
        {
          name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
          title: defineMessage({
            defaultMessage: 'I frequently had an upset stomach, headaches, or pain in my back, arms, legs, or joints',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
            },
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Physical Health and Activity',
        description: 'Wellness Check In: Header for the Physical Health and Activity Domain',
      }),
      mobilePage: 18,
      items: [
        {
          name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
          title: defineMessage({
            defaultMessage: 'I found it difficult to find time to relax or do things I enjoy',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
            },
            {
              fact: WellnessCheckInDomain.PhysicalHealthAndActivity,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iFeltLikeMyHouseholdBudgetWasTight: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Finances and Work',
        description: 'Wellness Check In: Header for the Finances and Work Domain',
      }),
      mobilePage: 19,
      items: [
        {
          name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
          title: defineMessage({
            defaultMessage: 'I felt like my household budget was tight',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
            },
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iFeltLikeICouldntCatchUpFinancially: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Finances and Work',
        description: 'Wellness Check In: Header for the Finances and Work Domain',
      }),
      mobilePage: 20,
      items: [
        {
          name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
          title: defineMessage({
            defaultMessage: "I felt like I couldn't catch up financially",
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
            },
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iEnjoyedGoingToWorkOnMostDays: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Finances and Work',
        description: 'Wellness Check In: Header for the Finances and Work Domain',
      }),
      mobilePage: 21,
      items: [
        {
          name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
          title: defineMessage({
            defaultMessage: 'I enjoyed going to work on most days',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
            },
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    iWasEmotionallyExhaustedInRelationToMyWorkRoles: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Finances and Work',
        description: 'Wellness Check In: Header for the Finances and Work Domain',
      }),
      mobilePage: 22,
      items: [
        {
          name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
          title: defineMessage({
            defaultMessage: 'I was emotionally exhausted in relation to my work roles',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
        },
        lowWellbeingCondition: {
          fact: WellnessCheckInDomain.FinancesAndWork,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
        },
        highWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
            },
            {
              fact: WellnessCheckInDomain.FinancesAndWork,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
            },
          ],
        },
      },
      valency: WellnessCheckInValency.Low,
    },
    iTriedNewThings: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Environment, Curiosity, and Spirituality',
        description: 'Wellness Check In: Header for the Environment, Curiosity, and Spirituality Domain',
      }),
      mobilePage: 23,
      items: [
        {
          name: `${WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality}.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
          title: defineMessage({
            defaultMessage: 'I tried new things',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
            },
            {
              fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    mySpiritualNeedsWereMet: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Environment, Curiosity, and Spirituality',
        description: 'Wellness Check In: Header for the Environment, Curiosity, and Spirituality Domain',
      }),
      mobilePage: 24,
      items: [
        {
          name: `${WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality}.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
          title: defineMessage({
            defaultMessage: 'My spiritual needs were met',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
            },
            {
              fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
    iSpentMeaningfulTimeOutsideInNature: {
      type: 'array',
      title: defineMessage({
        defaultMessage: 'Environment, Curiosity, and Spirituality',
        description: 'Wellness Check In: Header for the Environment, Curiosity, and Spirituality Domain',
      }),
      mobilePage: 25,
      items: [
        {
          name: `${WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality}.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
          title: defineMessage({
            defaultMessage: 'I spent meaningful time outside in nature',
            description:
              'Text above input field where user can indicate how much they agree or disagree with the statement',
          }),
          maximum: 6,
          minimum: 0,
          type: 'integer',
        },
      ],
      engineRuleConditions: {
        answeredCondition: {
          fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
          operator: 'greaterThanInclusive',
          value: 1,
          path: `$.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
        },
        lowWellbeingCondition: {
          all: [
            {
              fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
              operator: 'greaterThanInclusive',
              value: 1,
              path: `$.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
            },
            {
              fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
              operator: 'lessThanInclusive',
              value: 3,
              path: `$.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
            },
          ],
        },
        highWellbeingCondition: {
          fact: WellnessCheckInDomain.EnvironmentCuriosityAndSpirituality,
          operator: 'greaterThanInclusive',
          value: 4,
          path: `$.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
        },
      },
      valency: WellnessCheckInValency.High,
    },
  },
}

export const wellnessCheckInUiSchema = {
  'ui:order': [
    'howAreYouFeelingToday',
    'reflectionInstructions',
    'iFeltDownOrDepressed',
    'iFeltAnxiousOrStressed',
    'iWorriedAboutThingsICouldNotControl',
    'iLookedForwardToEachNewDay',
    'iExperiencedMoodSwings',
    'iGotDiscourageWhenThingsDidntGoMyWay',
    'iFeltLikeIWasOnMyOwnWhenFacingChallenges',
    'iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt',
    'itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend',
    'iWasOverwhelmedWithWhatIHadToAccomplishInADay',
    'myRelationshipsAtWorkWereSatisfying',
    'iFeltFulfilledInMyCloseRelationships',
    'iDidntGetEnoughPhysicalActivityOrExercise',
    'iOftenFeltRestedWhenIWokeUp',
    'iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints',
    'iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy',
    'iFeltLikeMyHouseholdBudgetWasTight',
    'iFeltLikeICouldntCatchUpFinancially',
    'iEnjoyedGoingToWorkOnMostDays',
    'iWasEmotionallyExhaustedInRelationToMyWorkRoles',
    'iTriedNewThings',
    'mySpiritualNeedsWereMet',
    'iSpentMeaningfulTimeOutsideInNature',
  ],
  howAreYouFeelingToday: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFeltDownOrDepressed: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFeltAnxiousOrStressed: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iWorriedAboutThingsICouldNotControl: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iLookedForwardToEachNewDay: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iExperiencedMoodSwings: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iGotDiscourageWhenThingsDidntGoMyWay: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFeltLikeIWasOnMyOwnWhenFacingChallenges: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iWasOverwhelmedWithWhatIHadToAccomplishInADay: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  myRelationshipsAtWorkWereSatisfying: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFeltFulfilledInMyCloseRelationships: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iDidntGetEnoughPhysicalActivityOrExercise: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iOftenFeltRestedWhenIWokeUp: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFeltLikeMyHouseholdBudgetWasTight: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iFeltLikeICouldntCatchUpFinancially: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iEnjoyedGoingToWorkOnMostDays: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iWasEmotionallyExhaustedInRelationToMyWorkRoles: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iTriedNewThings: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  mySpiritualNeedsWereMet: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
  iSpentMeaningfulTimeOutsideInNature: {
    items: [
      {
        'ui:widget': 'range',
      },
    ],
  },
}
