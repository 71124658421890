import { useMemo } from 'react'

import { PathwayOptions, TEEN_ENABLED_PATHWAYS } from '../../pathways/constants'

export const useShouldShowForkedTriageEntrypoint = ({ isUserTeen }: { isUserTeen: boolean }) => {
  return useMemo(
    () =>
      // Essentials is a pathway option that does NOT have an entrypoint in Forked Triage
      isUserTeen ? TEEN_ENABLED_PATHWAYS.filter((option) => option !== PathwayOptions.ESSENTIALS).length > 1 : true,
    [isUserTeen],
  )
}
