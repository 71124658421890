import { useMemo } from 'react'

import { useAddCustomerTreatmentOptions } from './useAddCustomerTreatmentOptions'
import { isAgeTeen, isAgeYoungerThanTeen } from '../../../features/common/utils/userUtils'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../../../features/searchForCare/constants'
import {
  CustomerCopy,
  CustomerTreatmentOption,
  CustomerTreatmentsOptionLocation,
  TreatmentRecommendations,
} from '../../../models'
import {
  childPrimaryTreatmentOptionsToDisplay,
  childSecondaryTreatmentOptionsToDisplay,
  tierOnePrimaryTreatmentOptionsToDisplay,
  tierOneSecondaryTreatmentOptionsToDisplay,
  tierThreePrimaryTreatmentOptionsToDisplay,
  tierThreeSecondaryTreatmentOptionsToDisplay,
  tierTwoPrimaryTreatmentOptionsToDisplay,
  tierTwoSecondaryTreatmentOptionsToDisplay,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { TREATMENT_RECOMMENDATION_TIER } from '../../common/constants/treatmentRecommendationsConstants'

export type CustomTreatmentCardConfig = {
  [key in TREATMENT_OPTIONS_TYPE]?: CustomerTreatmentOption['config']
}

export type useGetTreatmentOptionsV2Args = {
  treatmentRecs: TreatmentRecommendations | null
  searcherAge: number
  isAssertiveTriage: boolean
  customerCopy?: CustomerCopy
  hasAccessToInternationallySupportedTreatmentOptions: boolean
  isUserInternational?: boolean
  matchFor?: SEARCHER_TYPE_TO_MATCH_FOR
}

/**
 * Gets treatment options for the treatment recommendations page for s4c
 * - Filters down treatment recommendations by tier, if user is international
 * - Adds custom treatment options per customer
 * @param treatmentRecs Treatment recs returned from triage flow
 * @param customerCopy Used to add in custom treatment options based on customer
 * @param searcherAge Use for child searches
 */
export const useGetTreatmentOptionsV2 = ({
  treatmentRecs,
  searcherAge,
  isAssertiveTriage,
  customerCopy,
  isUserInternational,
  hasAccessToInternationallySupportedTreatmentOptions,
  matchFor,
}: useGetTreatmentOptionsV2Args) => {
  const addCustomerTreatmentOptions = useAddCustomerTreatmentOptions()

  const result = useMemo(() => {
    const configForSpecificTreatmentOption: CustomTreatmentCardConfig = {}

    if (
      isUserInternational &&
      (isAgeYoungerThanTeen(searcherAge) || matchFor === SEARCHER_TYPE_TO_MATCH_FOR.OTHER_CHILD)
    ) {
      return {
        primaryTreatmentOption: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD_ICAS,
        secondaryTreatmentOptions: [],
      }
    }

    if (isUserInternational && (isAgeTeen(searcherAge) || !hasAccessToInternationallySupportedTreatmentOptions)) {
      return {
        primaryTreatmentOption: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
        secondaryTreatmentOptions: [],
      }
    }

    if (treatmentRecs === null) {
      return {
        primaryTreatmentOption: undefined,
        secondaryTreatmentOptions: [],
        configForSpecificTreatmentOption,
      }
    }

    const shownTierPage = treatmentRecs.shownTierPage
    let primaryTreatmentRecs: TREATMENT_OPTIONS_TYPE[] = []
    let secondaryTreatmentRecs: TREATMENT_OPTIONS_TYPE[] = []

    if (searcherAge <= 17) {
      primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
        childPrimaryTreatmentOptionsToDisplay.includes(option),
      )
      secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
        childSecondaryTreatmentOptionsToDisplay.includes(option),
      )
    } else {
      switch (shownTierPage) {
        case TREATMENT_RECOMMENDATION_TIER.TIER_1:
          primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
            tierOnePrimaryTreatmentOptionsToDisplay.includes(option),
          )
          secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
            tierOneSecondaryTreatmentOptionsToDisplay.includes(option),
          )
          if (isAssertiveTriage) {
            secondaryTreatmentRecs = secondaryTreatmentRecs.filter(
              (option) => option !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
            )
          }
          break
        case TREATMENT_RECOMMENDATION_TIER.TIER_2:
          primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
            tierTwoPrimaryTreatmentOptionsToDisplay.includes(option),
          )
          secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
            tierTwoSecondaryTreatmentOptionsToDisplay.includes(option),
          )
          break
        case TREATMENT_RECOMMENDATION_TIER.TIER_3:
          primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
            tierThreePrimaryTreatmentOptionsToDisplay.includes(option),
          )
          secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
            tierThreeSecondaryTreatmentOptionsToDisplay.includes(option),
          )
          break
      }
    }

    // TODO: Do not show customer specific treatment options for international users
    // TODO: Change this to not mutate the result
    // customer treatment options are only shown in secondary cards
    addCustomerTreatmentOptions(
      searcherAge,
      shownTierPage as CustomerTreatmentsOptionLocation,
      secondaryTreatmentRecs,
      configForSpecificTreatmentOption,
      customerCopy,
    )

    return {
      primaryTreatmentOption: primaryTreatmentRecs[0],
      alternateTreatmentOption: primaryTreatmentRecs[1],
      secondaryTreatmentOptions: secondaryTreatmentRecs,
      // TODO: validate logic
      configForSpecificTreatmentOption,
    }
  }, [
    hasAccessToInternationallySupportedTreatmentOptions,
    treatmentRecs,
    addCustomerTreatmentOptions,
    searcherAge,
    customerCopy,
    isAssertiveTriage,
    isUserInternational,
    matchFor,
  ])

  return result
}
