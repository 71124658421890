import { useEffect } from 'react'

import { useFlags, useLyraIntl } from '@lyrahealth-inc/shared-app-logic'

import useScript from '../../../features/common/hooks/useScript'
import { DEFAULT_LANGUAGE } from '../constants'

/**
 * Visual context capture from DOM changes
 * See: https://help.smartling.com/hc/en-us/articles/360007996553-JavaScript-Context-Capture-Library
 *
 * orgId: organization ID, has a direct correlation to projectId
 */
function useSmartlingContextCaptureActual(orgId: string) {
  const { isSmartlingContextCaptureEnabled } = useFlags()
  const { activeLanguage } = useLyraIntl()

  const scriptStatus = useScript(
    isSmartlingContextCaptureEnabled ? '//d2c7xlmseob604.cloudfront.net/tracker.min.js' : null,
    /* defer */ true,
    /* crossorigin */ 'anonymous',
  )

  useEffect(() => {
    const w = window as Window & { SmartlingContextTracker?: any }
    if (isSmartlingContextCaptureEnabled && activeLanguage === DEFAULT_LANGUAGE && scriptStatus === 'ready') {
      console.log('Enabling Smartling context capture')
      w.SmartlingContextTracker.init({
        orgId,
        // If needed, expose these as options to the hook
        host: 'api.smartling.com',
        resourceFetchLimit: 100,
        cleanupInputFields: true,
        instrumentationProcessingEnabled: false,
        overrideContextOlderThanDays: 1,
        traverseElementsLimit: 20000,
        childElementsLimit: 2000,
      })
      w.SmartlingContextTracker.enable()
    } else if (w.SmartlingContextTracker) {
      console.log('Disabling Smartling context capture')
      w.SmartlingContextTracker.disable()
    }
  }, [activeLanguage, isSmartlingContextCaptureEnabled, orgId, scriptStatus])
}

const emptyFunction = () => {}

// Hook is no-op except only in staging
export const useSmartlingContextCapture =
  process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'prerelease'
    ? useSmartlingContextCaptureActual
    : emptyFunction
