import { useEffect, useState } from 'react'

import { isEqual } from 'lodash-es'

import { MEETING_FORMATS } from '../../../models'
import {
  emptyProviderPreferenceState,
  MEMBER_PREFERENCE_IDS,
  ProviderPreferenceValues,
  S4C_PROGRAM_OPTION_FLOW,
} from '../../memberPreferences/constants'
// Metadata imports -- long term consider migrating to the BE when we set up a MP service to fetch and serve these files
import { activeMemberPreferenceListOptions, PreferenceMetadataConfig } from '../../memberPreferences/metadata'
import { MemberPreferencesForUserV2 } from '../../memberPreferences/types'
import {
  filterMemberPreferencesOptions,
  getBipocUserPreferenceSelection,
  getInitialProviderPreferenceValues,
  getIsValidSearchForModalityPreferenceForMemberPreferences,
  getModalityPreferenceProgramOption,
  matchMeetingSettingFormatToMemberPreferencesValues,
  setMeetingFormatForUpdatedCoachingPreferences,
} from '../../memberPreferences/utils'

export interface useMemberPreferencesArgs {
  memberPreferencesV2: MemberPreferencesForUserV2 | null
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
  shouldShowMeetingStylePreferenceModal: boolean
  isSearchingForCoaching: boolean
  isSearchingForAdultTherapy: boolean
  postModalityPreferenceData: ({
    lyraId,
    searchId,
    data,
  }: {
    lyraId: string
    searchId: string
    data: { modalityPreferences: string[] }
  }) => Promise<any>
  userId: string
  selectedSearchId?: string | null
  setMeetingFormat: (value: string) => void
  isParentInitiatedInPersonS4CPreferenceChecked: boolean
  isParentInitiatedInPersonTeenS4CPreferenceChecked: boolean
  setIsParentInitiatedInPersonS4CPreferenceChecked: (value: boolean) => void
  setIsParentInitiatedInPersonTeenS4CPreferenceChecked: (value: boolean) => void
  saveMemberPreferenceForUserV2: ({
    lyraId,
    data,
  }: {
    lyraId: string
    data: MemberPreferencesForUserV2
  }) => Promise<any>
  setUserMemberPreferencesV2?: (value: MemberPreferencesForUserV2) => void // Mobile only, web fx is built into saveMemberPreferenceForUserV2
  onProviderPreferenceChangeFail: (err: any) => void
}

// A hook that takes in the redux state and actions from both web / mobile Preferences and generates the
// props needed for the provider preference selection page
export const useMemberPreferences = ({
  memberPreferencesV2,
  searchForCareProgramOptionFlow,
  shouldShowMeetingStylePreferenceModal,
  isSearchingForCoaching,
  isSearchingForAdultTherapy,
  postModalityPreferenceData,
  userId,
  selectedSearchId,
  setMeetingFormat,
  isParentInitiatedInPersonS4CPreferenceChecked,
  isParentInitiatedInPersonTeenS4CPreferenceChecked,
  setIsParentInitiatedInPersonS4CPreferenceChecked,
  setIsParentInitiatedInPersonTeenS4CPreferenceChecked,
  saveMemberPreferenceForUserV2,
  setUserMemberPreferencesV2,
  onProviderPreferenceChangeFail,
}: useMemberPreferencesArgs) => {
  const isValidSearchForModalityPreferenceForMemberPreferences =
    getIsValidSearchForModalityPreferenceForMemberPreferences({ searchForCareProgramOptionFlow })
  const isCoachingWithLiveMessagingSearch = shouldShowMeetingStylePreferenceModal && isSearchingForCoaching

  // Check if parent child search aged 0-12
  const isSearchingMemberPreferencesTreatmentOptionForChildTherapy =
    searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD
  // Check if parent child search aged 13-17
  const isSearchingMemberPreferencesTreatmentOptionForTeenTherapy =
    searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN

  // Determines which meeting setting preference user sees, if any
  const modalityPreferenceOption = getModalityPreferenceProgramOption({
    shouldShowMeetingStylePreferenceModal,
    isSelectedTreatmentOptionCoaching: isSearchingForCoaching,
  })

  /* State maintained by this hook for usage in web / mobile containers */
  const [memberPreferencesMetadata, setMemberPreferencesMetadata] = useState<PreferenceMetadataConfig[]>([])

  // Submitting state for updating selected preferences
  const [isSavingPreferences, setIsSavingPreferences] = useState(false)

  const [currentPreferences, setCurrentPreferences] = useState<ProviderPreferenceValues>(
    getInitialProviderPreferenceValues({
      memberPreferencesV2,
      isValidSearchForModalityPreferenceForMemberPreferences,
      isCoachingWithLiveMessagingSearch,
    }),
  )

  const [isResetDisabled, setResetDisabled] = useState(isEqual(currentPreferences, emptyProviderPreferenceState))

  const [isHowPreferencesWorkModalVisible, setIsHowPreferencesWorkModalVisible] = useState(false)

  useEffect(() => {
    const parseMemberPreferencesMetadata = ({
      fetchedPreferencesList,
    }: {
      fetchedPreferencesList: PreferenceMetadataConfig[]
    }) => {
      const parsedMetadataArray = fetchedPreferencesList
        .map((preferenceConfig: PreferenceMetadataConfig) => {
          // If this accordion is not visible for the current searchForCareProgramOptionFlow, skip it
          if (!preferenceConfig.visibleFor.includes(searchForCareProgramOptionFlow)) return null
          return preferenceConfig
        })
        .filter((metadata): metadata is PreferenceMetadataConfig => metadata !== null)

      setMemberPreferencesMetadata(parsedMetadataArray)
    }

    parseMemberPreferencesMetadata({ fetchedPreferencesList: activeMemberPreferenceListOptions })
  }, [searchForCareProgramOptionFlow, modalityPreferenceOption, setMemberPreferencesMetadata])

  const onPressResetButton = () => {
    if (isSearchingMemberPreferencesTreatmentOptionForChildTherapy) {
      setIsParentInitiatedInPersonS4CPreferenceChecked(false)
    }
  }

  // Whenever a user selects and saves any new specific preference, we re-create the preferences blob with ALL preferences
  // to update the store and save the preferences in the backend so that the user won't have to refill preferences during their next search
  const onProviderPreferenceChange = async (newPreferenceValues: ProviderPreferenceValues) => {
    setCurrentPreferences(newPreferenceValues)
    try {
      setIsSavingPreferences(true)

      const filteredEthnicities = filterMemberPreferencesOptions({
        preferenceCategory: newPreferenceValues.ethnicities,
        shouldFilterMemberPreferencesOptions: !isSearchingForAdultTherapy,
        filterOptions: [MEMBER_PREFERENCE_IDS.MIDDLE_EASTERN_OR_NORTH_AFRICAN],
      })

      const updateModalityPreferenceTherapyOption =
        isValidSearchForModalityPreferenceForMemberPreferences &&
        !isEqual(memberPreferencesV2?.preferredAppointmentType, newPreferenceValues.meetingSetting)

      const isLgbtqia = newPreferenceValues.isLgbtqia.length ? true : false
      const isBipoc = getBipocUserPreferenceSelection({ userPreferenceSelections: filteredEthnicities })

      // If the user is searching for therapy and the modality preference has been changed, update
      // the modality preference in the BE
      if (updateModalityPreferenceTherapyOption) {
        const isInPersonSelected = newPreferenceValues.meetingSetting.some(
          (val) => val === MEMBER_PREFERENCE_IDS.HIGHLIGHT_PROVIDERS_WHO_OFFER_IN_PERSON_SESSIONS,
        )
        await postModalityPreferenceData({
          lyraId: userId,
          searchId: selectedSearchId || '',
          data: { modalityPreferences: isInPersonSelected ? [MEETING_FORMATS.IN_PERSON] : [MEETING_FORMATS.VIDEO] },
        })
      } else if (isSearchingForCoaching) {
        const updatedValues = {
          [MEETING_FORMATS.LIVE_MESSAGING]: newPreferenceValues.meetingSetting.some(
            (val) => val === MEETING_FORMATS.LIVE_MESSAGING,
          ),
          [MEETING_FORMATS.VIDEO]: newPreferenceValues.meetingSetting.some((val) => val === MEETING_FORMATS.VIDEO),
        }

        setMeetingFormatForUpdatedCoachingPreferences({
          setMeetingFormatFn: setMeetingFormat,
          updatedValues,
        })
      }
      // Prevent in-person option from being checked when the user returns to the member preferences page
      if (
        isSearchingMemberPreferencesTreatmentOptionForChildTherapy &&
        isParentInitiatedInPersonS4CPreferenceChecked &&
        newPreferenceValues.meetingSetting.length === 0
      ) {
        setIsParentInitiatedInPersonS4CPreferenceChecked(false)
      } else if (
        isSearchingMemberPreferencesTreatmentOptionForTeenTherapy &&
        isParentInitiatedInPersonTeenS4CPreferenceChecked &&
        newPreferenceValues.meetingSetting.length > 0
      ) {
        setIsParentInitiatedInPersonTeenS4CPreferenceChecked(false)
      }

      const newPreferences = {
        ethnicities: newPreferenceValues.ethnicities,
        gender: newPreferenceValues.gender,
        isBipoc,
        isLgbtqia,
        religions: newPreferenceValues.religions,
        isExperiencedWithVeterans: newPreferenceValues.isExperiencedWithVeterans.length ? true : false,
        preferredAppointmentType: isValidSearchForModalityPreferenceForMemberPreferences
          ? matchMeetingSettingFormatToMemberPreferencesValues({ meetingSetting: newPreferenceValues.meetingSetting })
          : memberPreferencesV2?.preferredAppointmentType || [],
        preferredSessionFormat: isSearchingForCoaching
          ? matchMeetingSettingFormatToMemberPreferencesValues({ meetingSetting: newPreferenceValues.meetingSetting })
          : memberPreferencesV2?.preferredSessionFormat || [],
      }
      // Send updated preferences to BE to be saved in user_property table with isBipoc value
      await saveMemberPreferenceForUserV2({
        lyraId: userId,
        data: newPreferences,
      })
      // web version sets the user member preferences in store as apart of 'saveMemberPreferencesForUserV2'
      // mobile does it separately and will pass this fx below
      if (setUserMemberPreferencesV2) {
        // Update redux store with new preferences
        setUserMemberPreferencesV2(newPreferences)
      }
    } catch (err) {
      onProviderPreferenceChangeFail(err)
    } finally {
      setIsSavingPreferences(false)
    }
  }

  return {
    isHowPreferencesWorkModalVisible,
    setIsHowPreferencesWorkModalVisible,
    isResetDisabled,
    setResetDisabled,
    memberPreferencesMetadata,
    isSavingPreferences,
    currentPreferences,
    setCurrentPreferences,
    onProviderPreferenceChange,
    onPressResetButton,
  }
}
