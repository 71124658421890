import { MutableRefObject } from 'react'

import { useBackToTriage } from './useBackToTriage'
import { useGetTreatmentOptionsV2 } from './useGetTreatmentOptionsV2'
import {
  useSelectTreatmentOption,
  UseSelectTreatmentOptionArgs,
  NavigationRoutes as UseSelectTreatmentOptionNavigationRoutes,
} from './useSelectTreatmentOption'
import { unsupportedTreatmentOptionsConfigurations as defaultUnsupportedTreatmentOptionsConfigurations } from '../../../features/common/constants/baseTreatmentOptionConstants'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../../../features/searchForCare/constants'
import { CareNavigatorHeaderEntryPointModalProps, CustomerCopy } from '../../../models'
import {
  GetWordCloudWordsActionData,
  SearcherInfo,
  TriageSearch,
  WordCloudWords,
} from '../../../models/searchForCare/SearchForCare'
import { isAgeTeen, isAgeYoungerThanTeen } from '../../common/utils/userUtils'
import { getUnsupportedTreatmentOptionsConfiguration } from '../utils'

interface NavigationRoutes extends UseSelectTreatmentOptionNavigationRoutes {
  goBack: () => void
  onExit: () => void
  contactCareNavigatorConsolidated: () => void
}

export interface useTreatmentRecommendationsArgs extends Omit<UseSelectTreatmentOptionArgs, 'navigationRoutes'> {
  matchFor?: SEARCHER_TYPE_TO_MATCH_FOR
  navigationRoutes: NavigationRoutes
  backToTriageProps: {
    isCreatingSearch: boolean
    lyraSocialCareEnabled: boolean
    primaryNeedsWord: string
    searcherInfo?: SearcherInfo | null
    shouldCreateDuplicateInProgressSearch?: boolean
    country: string
    wordCloudWords: WordCloudWords | null
    getWordCloudWords: (data: GetWordCloudWordsActionData) => Promise<any>
    postTriageSearch: (arg: any) => Promise<any>
  }
  navBarProps: {
    a11yFocusedElementOnLoad?: MutableRefObject<any>
    shouldShowCareNavigatorHeaderEntrypoint: boolean
    careNavigatorHeaderEntryPointModalProps: CareNavigatorHeaderEntryPointModalProps
    careNavigatorConsolidatedBottomSheet: React.ReactNode
    isSidePanel?: boolean
  }
  // Booleans
  isUserInternational: boolean
  hasAccessToInternationallySupportedTreatmentOptions: boolean
  isDirectAccessMedsSupported: boolean
  isLctTeensEnabled: boolean
  shouldSeeCareAdvocateRequestForm: boolean
  // Treatment rec props
  customerCopy?: CustomerCopy
  tollFreeNumbers?: string[]
  outOfCountryNumbers?: string[]
  onEligibilityTooltipPressSeeDetails: () => void
  onPrivacyPolicyLinkPress: () => void
}

/**
 * Returns all the props needed to render the treatment recommendation v2 page
 */

export const useTreatmentRecommendationsV2 = ({
  treatmentRecs,
  navigationRoutes,
  backToTriageProps,
  navBarProps,
  currentProgramCoverageBreakdown,
  selectedSearch,
  patchTriageSearch,
  setMeetingFormat,
  setSelectedTreatmentOption,
  trackEvent,
  isUserInternational,
  hasAccessToInternationallySupportedTreatmentOptions,
  isLctTeensEnabled,
  isHealthPlanConfirmed,
  shouldSeeCareAdvocateRequestForm,
  customerCopy,
  tollFreeNumbers,
  outOfCountryNumbers,
  onEligibilityTooltipPressSeeDetails,
  matchFor,
  onPrivacyPolicyLinkPress,
}: useTreatmentRecommendationsArgs) => {
  const { age: searcherAge } = backToTriageProps.searcherInfo ?? { age: 18 }
  const treatmentOptions = useGetTreatmentOptionsV2({
    treatmentRecs,
    searcherAge,
    isAssertiveTriage: shouldSeeCareAdvocateRequestForm,
    hasAccessToInternationallySupportedTreatmentOptions,
    customerCopy,
    isUserInternational,
    matchFor,
  })

  const handleBackPress = useBackToTriage({
    goBack: navigationRoutes.goBack,
    search: selectedSearch as TriageSearch,
    isLctTeensEnabled,
    ...backToTriageProps,
  })

  const selectTreatmentOption = useSelectTreatmentOption({
    navigationRoutes: {
      locationFinder: navigationRoutes.locationFinder,
      healthPlanInfo: navigationRoutes.healthPlanInfo,
    },
    selectedSearch,
    treatmentRecs,
    currentProgramCoverageBreakdown,
    setMeetingFormat,
    patchTriageSearch,
    setSelectedTreatmentOption,
    trackEvent,
    isHealthPlanConfirmed,
  })

  // This checks if customer has disabled DA meds.
  const unsupportedTreatmentOptionsConfiguration = getUnsupportedTreatmentOptionsConfiguration({
    unsupportedTreatmentOptionsConfigurations: defaultUnsupportedTreatmentOptionsConfigurations,
    excludedTreatmentOptionsReasons: treatmentRecs?.excludedTreatmentOptionsReasons,
    isUserInternational,
  })

  return {
    onPrivacyPolicyLinkPress,
    onEligibilityTooltipPressSeeDetails,
    hasAccessToInternationallySupportedTreatmentOptions,
    primaryTreatmentOption: treatmentOptions.primaryTreatmentOption,
    alternateTreatmentOption: treatmentOptions.alternateTreatmentOption,
    secondaryTreatmentOptions: treatmentOptions.secondaryTreatmentOptions,
    configForSpecificTreatmentOption: treatmentOptions.configForSpecificTreatmentOption,
    showPairMeds: !!unsupportedTreatmentOptionsConfiguration?.title,
    programCoverage: currentProgramCoverageBreakdown,
    isInternational: isUserInternational,
    detailInfoPanelProps: {
      tier: treatmentRecs?.shownTierPage,
      isChild: isAgeYoungerThanTeen(searcherAge),
      isTeen: isAgeTeen(searcherAge),
      internationalHideCTA: isUserInternational,
      tollFreeNumbers,
      outOfCountryNumbers,
    },
    headerProps: {
      isInternational: isUserInternational,
      onBack: handleBackPress,
      onExit: navigationRoutes.onExit,
      testIdPrefix: 'NavBar',
      ...navBarProps,
    },
    onTreatmentOptionButtonPress: selectTreatmentOption,
    onPressCareNavigator: navigationRoutes.contactCareNavigatorConsolidated,
    tier: treatmentRecs?.shownTierPage,
  }
}
