import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { WellnessCheckInForm } from '@lyrahealth-inc/ui-core-crossplatform'

import { LOCAL_STORAGE_KEY } from '../../common/constants/appConstants'

// TODO Update UI when figma/design is ready
const WellnessCheckInFormContainer: FunctionComponent = ({}) => {
  const navigate = useNavigate()
  const { isWellnessCheckInEnabled } = useFlags()

  useEffect(() => {
    if (!isWellnessCheckInEnabled) {
      navigate('/')
    }
  }, [isWellnessCheckInEnabled, navigate])

  const onSubmit = (values: object) => {
    localStorage.setItem(LOCAL_STORAGE_KEY.WELLNESS_CHECK_IN, JSON.stringify(values))
    navigate('/login')
  }

  if (!isWellnessCheckInEnabled) {
    return <></>
  }

  return <WellnessCheckInForm onSubmit={onSubmit} />
}

export default WellnessCheckInFormContainer
